import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';
import InfoTooltip from '../Main/Dashboard/InfoTooltip';
import ChatIcon from '@mui/icons-material/Chat';

const StyledSlider = withStyles({
  root: {
    background: 'linear-gradient(to right, #E44D42 , #FEC945, #38CA74)',
    //background: 'linear-gradient(to right, #f64747, #FEC945, #2ecc71)',
    //background: 'linear-gradient(to right, #f62459, #fef160, #03c9a9)',
    color: '#76719fa0 !important',
  },
  disabled: {
    color: '#bdbdbd90 !important',
  },
})(Slider);

function valuetext(value) {
  return `${value}°C`;
}

export default function MinimumDistanceSlider({
  value0,
  value1,
  isDisabled,
  handleSliderChange,
  Icon = ChatIcon,
  title,
  tooltip,
}) {
  const handleChange = (event, newValue /*activeThumb*/) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    handleSliderChange(newValue[0], newValue[1]);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 400, display: 'inline' }}
        >
          {title}
        </Typography>
        <InfoTooltip title={tooltip} />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <Icon style={{ color: '#33333390' }} />
        </Grid>
        <Grid item>
          <Box sx={{ width: 200 }}>
            <StyledSlider
              getAriaLabel={() => 'Minimum distance'}
              value={[value0, value1]}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              disableSwap
              min={-100}
              max={100}
              step={1}
              marks={[
                { label: 'Negative', value: -100 },
                { label: '', value: -25 },
                { label: 'Neutral', value: 0 },
                { label: '', value: 25 },
                { label: 'Positive', value: 100 },
              ]}
              disabled={isDisabled}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
