// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import 'isomorphic-fetch';
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import firebase from '../../../backend/firebase';
import InputAdornment from '@mui/material/InputAdornment';
import lock from '../../assets/lock.svg';
import hashtag from '../../assets/hashtag.svg';
import Box from '@mui/material/Box';

export const getChannels = async (teamId, startsWith) => {
  const channelsPath = `/slack_teams/${teamId}/channels`;
  let query = firebase.firestore.collection(channelsPath);
  if (startsWith) {
    const upperBound = startsWith.replace(/.$/, (c) =>
      String.fromCharCode(c.charCodeAt(0) + 1)
    );
    query = query
      .where('name', '>=', startsWith)
      .where('name', '<', upperBound);
  }
  const snapshot = await query.orderBy('name').limit(50).get();
  const options = snapshot.docs.map((doc) => doc.data());
  return options;
};

export default function Asynchronous(props) {
  const { disabled, setSelectedChannel } = props;
  const teamId = useSelector((state) => state.auth.teamId);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    const startsWith = value;
    let active = true;
    setLoading(true);
    setOptions([]);

    (async () => {
      const options = await getChannels(teamId, startsWith);
      if (active) {
        setLoading(false);
        setOptions(options);
      }
    })();
    return () => {
      active = false;
    };
  }, [value, open]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return (
    <Autocomplete
      style={{ width: 300, borderRadius: 10 }}
      open={open}
      autoHighlight
      onOpen={async () => {
        setOpen(true);
        await getChannels(teamId, '', setLoading, setOptions);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            style={{ verticalAlign: 'middle' }}
            src={option.is_private ? lock : hashtag}
            height="20"
          />
          {option.name}
        </Box>
      )}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      onChange={(event, value) => {
        setSelectedChannel(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Channel name"
          placeholder="Type a channel name"
          fullWidth
          onChange={({ target: { value } }) => setValue(value)}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            disabled: disabled,
            startAdornment: (
              <InputAdornment position="start">
                <img src={hashtag} height="20" />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
