import React from 'react';

import logo from '../assets/logo.svg';

const Logo = ({ height, padding, borderRadius }) => (
  <img
    src={logo}
    height={height}
    alt="AnonymityBot Logo"
    style={{ borderRadius, padding }}
  />
);

Logo.defaultProps = {
  height: '50px',
  padding: 10,
  borderRadius: 25,
};

export default Logo;
