import React from 'react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import { Redirect } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LoadingPage from '../Common/LoadingPage';
import { signInWithCustomToken } from '../../actions';
import Grid from '@mui/material/Grid';
import art1 from '../assets/art1.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const animationData = require('../assets/star_success.json');

function SignInWithSlack(props) {
  const { queryString, signInWithCustomToken } = props;
  const customToken = queryString.get('customToken');
  const upgrade = queryString.get('upgrade');
  React.useEffect(() => {
    signInWithCustomToken(customToken);
  }, [customToken]);

  let redirectUrl = '/dashboard/';
  if (upgrade) {
    redirectUrl += '?upgrade';
  }
  return <Redirect to={redirectUrl} />;
}

function AddToSlack(props) {
  const [toRedirect, setToRedirect] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setToRedirect(true), 4000);
  }, [setToRedirect]);

  const { queryString } = props;
  const teamName = queryString.get('teamName');
  const teamDomain = queryString.get('teamDomain');

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (toRedirect && teamDomain) {
    window.location = `https://${teamDomain}.slack.com`;
  }

  return (
    <div>
      <Lottie
        options={defaultLottieOptions}
        height={400}
        width={400}
        isStopped={false}
        isPaused={false}
      />
      <div style={{ textAlign: 'center', margin: '10px' }}>
        <Typography variant="h4" gutterBottom>
          Success!
        </Typography>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          AnonymityBot was installed for the {teamName} workspace!
        </Typography>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          Redirecting to your Slack...
        </Typography>
      </div>
    </div>
  );
}

const UpperLeft = () => {
  return (
    <Grid
      item
      key={1}
      style={{
        textAlign: 'left',
        marginTop: '20px',
        marginLeft: '20px',
        marginRight: '20px',
        maxWidth: '450px',
      }}
    >
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          Hey there! 👋
        </Typography>
        <Typography variant="h5" gutterBottom style={{ color: '#555' }}>
          {`You've just signed in!`}
        </Typography>
        <Typography variant="h5" gutterBottom style={{ color: '#555' }}>
          {`But... it appears that AnonymityBot isn't currently installed on your Slack workspace.`}
        </Typography>
        <Typography variant="h5" gutterBottom style={{ color: '#555' }}>
          {`Please add it by clicking on 'Add to Slack' at `}
          <a href="https://anonymitybot.com/">anonymitybot.com</a>
        </Typography>

        <Grid item>
          <Grid
            container
            key={2}
            spacing={8}
            direction="column"
            style={{ paddingTop: 20 }}
          ></Grid>
        </Grid>
      </React.Fragment>
    </Grid>
  );
};

const UpperRight = () => {
  return (
    <Grid
      item
      key={2}
      style={{ maxWidth: '550px', width: '100%', textAlign: 'center' }}
    >
      <img
        src={art1}
        alt="AnonymityBot Art"
        style={{
          maxWidth: '600px',
          minWidth: '300px',
          paddingLeft: '20px',
          paddingRight: '20px',
          // https://stackoverflow.com/a/5219611
          boxSizing: 'border-box',
          width: '100%',
        }}
      />
    </Grid>
  );
};

const UpperGrid = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="row"
      style={{
        marginTop: matches ? '20px' : '40px',
        marginBottom: matches ? '20px' : '40px',
      }}
    >
      <UpperLeft />
      <UpperRight />
    </Grid>
  );
};

function SignInWithSlackAppNotInstalled() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ flex: 1 }}
    >
      <UpperGrid />
    </Grid>
  );
}

function Success(props) {
  const { inProgress, signInWithCustomToken } = props;
  const queryString = new URLSearchParams(props.location.search);
  const type = queryString.get('type');

  if (inProgress) {
    return <LoadingPage />;
  }

  switch (type) {
    case 'add_to_slack':
      return <AddToSlack queryString={queryString} />;
    case 'signin_with_slack':
      return (
        <SignInWithSlack
          queryString={queryString}
          signInWithCustomToken={signInWithCustomToken}
        />
      );
    case 'signin_with_slack_app_not_installed':
      return <SignInWithSlackAppNotInstalled />;

    default:
      return <Redirect to="/failure" />;
  }
}

const mapStateToProps = (state) => ({
  inProgress: !state.auth.isInitialized || state.auth.inProgress,
});

const mapDispatchToProps = {
  signInWithCustomToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
