import { store } from '../index';

const { REACT_APP_API_BACKEND_FUNCTION_HOST } = process.env;

export default async (idToken, apiEndpoint, data = {}) => {
  const { teamId, userId } = store.getState().auth;
  const body = JSON.stringify({ idToken, userId, teamId, ...data });
  const headers = { 'content-type': 'application/json' };
  const options = { method: 'POST', mode: 'cors', headers, body };

  const response = await fetch(
    `https://${REACT_APP_API_BACKEND_FUNCTION_HOST}/api/${apiEndpoint}`,
    options
  );
  if (response.status === 500) {
    const responseJSON = await response.json();
    console.log(responseJSON);
    throw new Error(`Server error: ${responseJSON.error}`);
  }
  const responseJSON = await response.json();
  return responseJSON;
};
