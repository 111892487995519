import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const SlackSvgIcon = ({ marginRight = '10px' }) => (
  <SvgIcon
    height="122.8"
    width="122.8"
    viewBox="0 0 122.8 122.8"
    y="0px"
    x="0px"
    id="Layer_1"
    version="1.1"
    style={{ marginRight }}
  >
    <g transform="translate(-73.6,-73.6)" id="g26">
      <g id="g6">
        <path
          fill="#ffffff"
          id="path2"
          d="m 99.4,151.2 c 0,7.1 -5.8,12.9 -12.9,12.9 -7.1,0 -12.9,-5.8 -12.9,-12.9 0,-7.1 5.8,-12.9 12.9,-12.9 h 12.9 z"
        />
        <path
          fill="#ffffff"
          id="path4"
          d="m 105.9,151.2 c 0,-7.1 5.8,-12.9 12.9,-12.9 7.1,0 12.9,5.8 12.9,12.9 v 32.3 c 0,7.1 -5.8,12.9 -12.9,12.9 -7.1,0 -12.9,-5.8 -12.9,-12.9 z"
        />
      </g>
      <g id="g12">
        <path
          fill="#ffffff"
          id="path8"
          d="m 118.8,99.4 c -7.1,0 -12.9,-5.8 -12.9,-12.9 0,-7.1 5.8,-12.9 12.9,-12.9 7.1,0 12.9,5.8 12.9,12.9 v 12.9 z"
        />
        <path
          fill="#ffffff"
          id="path10"
          d="m 118.8,105.9 c 7.1,0 12.9,5.8 12.9,12.9 0,7.1 -5.8,12.9 -12.9,12.9 H 86.5 c -7.1,0 -12.9,-5.8 -12.9,-12.9 0,-7.1 5.8,-12.9 12.9,-12.9 z"
        />
      </g>
      <g id="g18">
        <path
          fill="#ffffff"
          id="path14"
          d="m 170.6,118.8 c 0,-7.1 5.8,-12.9 12.9,-12.9 7.1,0 12.9,5.8 12.9,12.9 0,7.1 -5.8,12.9 -12.9,12.9 h -12.9 z"
        />
        <path
          fill="#ffffff"
          id="path16"
          d="m 164.1,118.8 c 0,7.1 -5.8,12.9 -12.9,12.9 -7.1,0 -12.9,-5.8 -12.9,-12.9 V 86.5 c 0,-7.1 5.8,-12.9 12.9,-12.9 7.1,0 12.9,5.8 12.9,12.9 z"
        />
      </g>
      <g id="g24">
        <path
          fill="#ffffff"
          id="path20"
          d="m 151.2,170.6 c 7.1,0 12.9,5.8 12.9,12.9 0,7.1 -5.8,12.9 -12.9,12.9 -7.1,0 -12.9,-5.8 -12.9,-12.9 v -12.9 z"
        />
        <path
          fill="#ffffff"
          id="path22"
          d="m 151.2,164.1 c -7.1,0 -12.9,-5.8 -12.9,-12.9 0,-7.1 5.8,-12.9 12.9,-12.9 h 32.3 c 7.1,0 12.9,5.8 12.9,12.9 0,7.1 -5.8,12.9 -12.9,12.9 z"
        />
      </g>
    </g>
  </SvgIcon>
);
