import backendApiRequest from './apiRequest';

export const getUserData = (idToken) =>
  backendApiRequest(idToken, 'slack/getUserData');

export const getTeamData = (idToken) =>
  backendApiRequest(idToken, 'slack/getTeamData');

export const getChannels = (idToken) =>
  backendApiRequest(idToken, 'slack/getChannels');

export const getCommonPrivateChannels = (idToken) =>
  backendApiRequest(idToken, 'slack/getCommonPrivateChannels');

export const getChannel = (idToken) =>
  backendApiRequest(idToken, 'slack/getChannel');

export const toggleWhitelist = (idToken, data) =>
  backendApiRequest(idToken, 'slack/toggleWhitelist', data);

export const addToWhitelist = (idToken, data) =>
  backendApiRequest(idToken, 'slack/addToWhitelist', data);

export const removeFromWhitelist = (idToken, data) =>
  backendApiRequest(idToken, 'slack/removeFromWhitelist', data);

export const toggleWhisperChannels = (idToken, data) =>
  backendApiRequest(idToken, 'slack/toggleWhisperChannels', data);

export const addToWhisperChannels = (idToken, data) =>
  backendApiRequest(idToken, 'slack/addToWhisperChannels', data);

export const removeFromWhisperChannels = (idToken, data) =>
  backendApiRequest(idToken, 'slack/removeFromWhisperChannels', data);

export const updateSettings = (idToken, data) =>
  backendApiRequest(idToken, 'slack/updateSettings', data);

export const triggerPubSubPostRegister = (idToken, data) =>
  backendApiRequest(idToken, 'slack/triggerPubSubPostRegister', data);

export const triggerPubSubRefreshUsers = (idToken, data) =>
  backendApiRequest(idToken, 'slack/triggerPubSubRefreshUsers', data);

export const getEnterprisePaymentLink = (idToken, data) =>
  backendApiRequest(idToken, 'slack/getEnterprisePaymentLink', data);

export const getTransactions = (idToken, data) =>
  backendApiRequest(idToken, 'slack/getTransactions', data);
export const getSubscriptionDetails = (idToken, data) =>
  backendApiRequest(idToken, 'slack/getSubscriptionDetails', data);
