import React from 'react';
import CardProgress from '../../Common/CardProgress';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControlLabel,
  Switch,
  Typography,
  CardContent,
  Button,
} from '@mui/material';
import * as actions from '../../../actions';

import TeamSelect from '../Dashboard/TeamSelect';

const GodPanel = () => {
  const { email, isSignedIn } = useSelector((state) => state.auth);

  const isGod = email === 'hey@anonymitybot.com';
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (isSignedIn) {
      //dispatch(listenToTeamData({ teamId: 'T016073E72P' }));
    }
  }, [isSignedIn]);

  if (!isGod) {
    return '';
  }
  const setSelectedTeamDomain = ({ team_id }) =>
    dispatch(actions.setAuthStateTeamId(team_id));

  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isCurrentlyPaying, setIsCurrentlyPayment] = React.useState(false);

  const handleIsAdminToggle = () => {
    dispatch(actions.setAuthStateIsAdmin(!isAdmin));
    setIsAdmin(!isAdmin);
  };

  return (
    <CardProgress>
      <CardContent
        style={{
          padding: '16px 24px',
          backgroundColor: '#f1a9a0',
        }}
      >
        <Typography variant="h6" gutterBottom>
          God Panel
        </Typography>
        <TeamSelect
          setSelectedTeamDomain={setSelectedTeamDomain}
          disabed={false}
        />
        <FormControlLabel
          control={
            <Switch
              style={{ justifyContent: 'end' }}
              checked={isAdmin}
              onChange={handleIsAdminToggle}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          label="isAdmin"
        />
        <FormControlLabel
          control={
            <Switch
              style={{ justifyContent: 'end' }}
              checked={isCurrentlyPaying}
              onChange={() => setIsCurrentlyPayment(!isCurrentlyPaying)}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          label="isCurrentlyPaying"
        />

        <Button onClick={() => dispatch(actions.triggerPubSubPostRegister())}>
          trigger pubsub
        </Button>
        <Button onClick={() => dispatch(actions.triggerPubSubRefreshUsers())}>
          trigger refresh users
        </Button>
      </CardContent>
    </CardProgress>
  );
};

export default GodPanel;
