const REQUEST = '_REQUEST';
const SUCCESS = '_SUCCESS';
const FAILURE = '_FAILURE';

// ## AUTH ##

export const SIGN_IN_WITH_CUSTOM_TOKEN = 'SIGN_IN_WITH_CUSTOM_TOKEN';
export const SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST =
  SIGN_IN_WITH_CUSTOM_TOKEN + REQUEST;
export const SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS =
  SIGN_IN_WITH_CUSTOM_TOKEN + SUCCESS;
export const SIGN_IN_WITH_CUSTOM_TOKEN_FAILURE =
  SIGN_IN_WITH_CUSTOM_TOKEN + FAILURE;

export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED';
export const NO_AUTH_STATE = 'NO_STATE_CHANGED';
export const LISTEN_AUTH_STATE_REQUESTED = 'LISTEN_AUTH_STATE_REQUESTED';
export const SET_AUTH_STATE_TEAM_ID = 'SET_AUTH_STATE_TEAM_ID';
export const SET_AUTH_STATE_IS_ADMIN = 'SET_AUTH_STATE_IS_ADMIN';

// ## DATABASE ##

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_REQUEST = GET_USER_DATA + REQUEST;
export const GET_USER_DATA_SUCCESS = GET_USER_DATA + SUCCESS;
export const GET_USER_DATA_FAILURE = GET_USER_DATA + FAILURE;

export const GET_TEAM_DATA = 'GET_TEAM_DATA';
export const GET_TEAM_DATA_REQUEST = GET_TEAM_DATA + REQUEST;
export const GET_TEAM_DATA_SUCCESS = GET_TEAM_DATA + SUCCESS;
export const GET_TEAM_DATA_FAILURE = GET_TEAM_DATA + FAILURE;

export const GET_CHANNELS = 'GET_CHANNELS';
export const GET_CHANNELS_REQUEST = GET_CHANNELS + REQUEST;
export const GET_CHANNELS_SUCCESS = GET_CHANNELS + SUCCESS;
export const GET_CHANNELS_FAILURE = GET_CHANNELS + FAILURE;

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_REQUEST = GET_TRANSACTIONS + REQUEST;
export const GET_TRANSACTIONS_SUCCESS = GET_TRANSACTIONS + SUCCESS;
export const GET_TRANSACTIONS_FAILURE = GET_TRANSACTIONS + FAILURE;

export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
export const GET_SUBSCRIPTION_DETAILS_REQUEST =
  GET_SUBSCRIPTION_DETAILS + REQUEST;
export const GET_SUBSCRIPTION_DETAILS_SUCCESS =
  GET_SUBSCRIPTION_DETAILS + SUCCESS;
export const GET_SUBSCRIPTION_DETAILS_FAILURE =
  GET_SUBSCRIPTION_DETAILS + FAILURE;

export const GET_ENTERPRISE_PAYMENT_LINK = 'GET_ENTERPRISE_PAYMENT_LINK';
export const GET_ENTERPRISE_PAYMENT_LINK_REQUEST =
  GET_ENTERPRISE_PAYMENT_LINK + REQUEST;
export const GET_ENTERPRISE_PAYMENT_LINK_SUCCESS =
  GET_ENTERPRISE_PAYMENT_LINK + SUCCESS;
export const GET_ENTERPRISE_PAYMENT_LINK_FAILURE =
  GET_ENTERPRISE_PAYMENT_LINK + FAILURE;

export const GET_COMMON_PRIVATE_CHANNELS = 'GET_COMMON_PRIVATE_CHANNELS';
export const GET_COMMON_PRIVATE_CHANNELS_REQUEST =
  GET_COMMON_PRIVATE_CHANNELS + REQUEST;
export const GET_COMMON_PRIVATE_CHANNELS_SUCCESS =
  GET_COMMON_PRIVATE_CHANNELS + SUCCESS;
export const GET_COMMON_PRIVATE_CHANNELS_FAILURE =
  GET_COMMON_PRIVATE_CHANNELS + FAILURE;

export const TOGGLE_WHITELIST = 'TOGGLE_WHITELIST';
export const TOGGLE_WHITELIST_REQUEST = TOGGLE_WHITELIST + REQUEST;
export const TOGGLE_WHITELIST_SUCCESS = TOGGLE_WHITELIST + SUCCESS;
export const TOGGLE_WHITELIST_FAILURE = TOGGLE_WHITELIST + FAILURE;

export const REMOVE_FROM_WHITELIST = 'REMOVE_FROM_WHITELIST';
export const REMOVE_FROM_WHITELIST_REQUEST = REMOVE_FROM_WHITELIST + REQUEST;
export const REMOVE_FROM_WHITELIST_SUCCESS = REMOVE_FROM_WHITELIST + SUCCESS;
export const REMOVE_FROM_WHITELIST_FAILURE = REMOVE_FROM_WHITELIST + FAILURE;

export const ADD_TO_WHITELIST = 'ADD_TO_WHITELIST';
export const ADD_TO_WHITELIST_REQUEST = ADD_TO_WHITELIST + REQUEST;
export const ADD_TO_WHITELIST_SUCCESS = ADD_TO_WHITELIST + SUCCESS;
export const ADD_TO_WHITELIST_FAILURE = ADD_TO_WHITELIST + FAILURE;

export const LISTEN_TO_WHITELIST = 'LISTEN_TO_WHITELIST';
export const LISTEN_TO_WHITELIST_REQUEST = LISTEN_TO_WHITELIST + REQUEST;
export const LISTEN_TO_WHITELIST_SUCCESS = LISTEN_TO_WHITELIST + SUCCESS;
export const LISTEN_TO_WHITELIST_FAILURE = LISTEN_TO_WHITELIST + FAILURE;

export const TOGGLE_WHISPER_CHANNELS = 'TOGGLE_WHISPER_CHANNELS';
export const TOGGLE_WHISPER_CHANNELS_REQUEST =
  TOGGLE_WHISPER_CHANNELS + REQUEST;
export const TOGGLE_WHISPER_CHANNELS_SUCCESS =
  TOGGLE_WHISPER_CHANNELS + SUCCESS;
export const TOGGLE_WHISPER_CHANNELS_FAILURE =
  TOGGLE_WHISPER_CHANNELS + FAILURE;

export const REMOVE_FROM_WHISPER_CHANNELS = 'REMOVE_FROM_WHISPER_CHANNELS';
export const REMOVE_FROM_WHISPER_CHANNELS_REQUEST =
  REMOVE_FROM_WHISPER_CHANNELS + REQUEST;
export const REMOVE_FROM_WHISPER_CHANNELS_SUCCESS =
  REMOVE_FROM_WHISPER_CHANNELS + SUCCESS;
export const REMOVE_FROM_WHISPER_CHANNELS_FAILURE =
  REMOVE_FROM_WHISPER_CHANNELS + FAILURE;

export const ADD_TO_WHISPER_CHANNELS = 'ADD_TO_WHISPER_CHANNELS';
export const ADD_TO_WHISPER_CHANNELS_REQUEST =
  ADD_TO_WHISPER_CHANNELS + REQUEST;
export const ADD_TO_WHISPER_CHANNELS_SUCCESS =
  ADD_TO_WHISPER_CHANNELS + SUCCESS;
export const ADD_TO_WHISPER_CHANNELS_FAILURE =
  ADD_TO_WHISPER_CHANNELS + FAILURE;

export const LISTEN_TO_WHISPER_CHANNELS = 'LISTEN_TO_WHISPER_CHANNELS';
export const LISTEN_TO_WHISPER_CHANNELS_REQUEST =
  LISTEN_TO_WHISPER_CHANNELS + REQUEST;
export const LISTEN_TO_WHISPER_CHANNELS_SUCCESS =
  LISTEN_TO_WHISPER_CHANNELS + SUCCESS;
export const LISTEN_TO_WHISPER_CHANNELS_FAILURE =
  LISTEN_TO_WHISPER_CHANNELS + FAILURE;

export const LISTEN_TO_TEAM_DATA = 'LISTEN_TO_TEAM_DATA';
export const LISTEN_TO_TEAM_DATA_REQUEST = LISTEN_TO_TEAM_DATA + REQUEST;
export const LISTEN_TO_TEAM_DATA_SUCCESS = LISTEN_TO_TEAM_DATA + SUCCESS;
export const LISTEN_TO_TEAM_DATA_FAILURE = LISTEN_TO_TEAM_DATA + FAILURE;

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_REQUEST = UPDATE_SETTINGS + REQUEST;
export const UPDATE_SETTINGS_SUCCESS = UPDATE_SETTINGS + SUCCESS;
export const UPDATE_SETTINGS_FAILURE = UPDATE_SETTINGS + FAILURE;

export const TRIGGER_PUBSUB_POST_REGISTER = 'TRIGGER_PUBSUB_POST_REGISTER';
export const TRIGGER_PUBSUB_POST_REGISTER_REQUEST =
  TRIGGER_PUBSUB_POST_REGISTER + REQUEST;
export const TRIGGER_PUBSUB_POST_REGISTER_SUCCESS =
  TRIGGER_PUBSUB_POST_REGISTER + SUCCESS;
export const TRIGGER_PUBSUB_POST_REGISTER_FAILURE =
  TRIGGER_PUBSUB_POST_REGISTER + FAILURE;

export const TRIGGER_PUBSUB_REFRESH_USERS = 'TRIGGER_PUBSUB_REFRESH_USERS';
export const TRIGGER_PUBSUB_REFRESH_USERS_REQUEST =
  TRIGGER_PUBSUB_REFRESH_USERS + REQUEST;
export const TRIGGER_PUBSUB_REFRESH_USERS_SUCCESS =
  TRIGGER_PUBSUB_REFRESH_USERS + SUCCESS;
export const TRIGGER_PUBSUB_REFRESH_USERS_FAILURE =
  TRIGGER_PUBSUB_REFRESH_USERS + FAILURE;

export const LISTEN_TO_MONTHLY_USAGE = 'LISTEN_TO_MONTHLY_USAGE';
export const LISTEN_TO_MONTHLY_USAGE_REQUEST =
  LISTEN_TO_MONTHLY_USAGE + REQUEST;
export const LISTEN_TO_MONTHLY_USAGE_SUCCESS =
  LISTEN_TO_MONTHLY_USAGE + SUCCESS;
export const LISTEN_TO_MONTHLY_USAGE_FAILURE =
  LISTEN_TO_MONTHLY_USAGE + FAILURE;

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_REQUEST = SIGN_OUT + REQUEST;
export const SIGN_OUT_SUCCESS = SIGN_OUT + SUCCESS;
export const SIGN_OUT_FAILURE = SIGN_OUT + FAILURE;

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// ## MISC ##

export const CLEAR_REDUX = 'CLEAR_REDUX';
