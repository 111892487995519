import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { Fragment } from 'react';
import HeaderButton from './HeaderButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import NotesIcon from '@mui/icons-material/Notes';
import MenuIcon from '@mui/icons-material/Menu';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import LoggedInMenuBar from './LoggedInMenuBar';

const linkStyle = {
  textDecoration: 'none',
  color: 'black',
  fontWeight: 700,
};

const useStyles = makeStyles({
  paper: { borderRadius: 10 },
});

const ResponsiveMenuItem = ({ text, icon, onClick }) => (
  <MenuItem
    onClick={onClick}
    style={{
      fontSize: '0.875rem',
      fontWeight: 500,
    }}
  >
    {icon && icon}
    {text}
  </MenuItem>
);

const MobileMenuBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon style={{ fontSize: '1.5em', color: 'rgba(0, 0, 0, 0.87)' }} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        classes={{ paper: classes.paper }}
      >
        <MenuList>
          <a
            href="https://anonymitybot.com/"
            style={linkStyle}
            onClick={handleClose}
          >
            <ResponsiveMenuItem
              icon={<AccountBalanceIcon style={{ marginRight: '8px' }} />}
              text="Home"
            />
          </a>

          <a
            href="https://anonymitybot.com/slack/faq/"
            style={linkStyle}
            onClick={handleClose}
          >
            <ResponsiveMenuItem
              icon={<QuestionAnswerIcon style={{ marginRight: '8px' }} />}
              text="FAQ"
            />
          </a>
          <a
            href="https://anonymitybot.com/blog/"
            style={linkStyle}
            onClick={handleClose}
          >
            <ResponsiveMenuItem
              icon={<NotesIcon style={{ marginRight: '8px' }} />}
              text="Blog"
            />
          </a>
        </MenuList>
      </Menu>
    </div>
  );
};

const RegularMenuBar = () => {
  return (
    <Fragment>
      <a style={linkStyle} href="https://anonymitybot.com/">
        <HeaderButton
          style={{ textTransform: 'none', fontSize: 16, fontWeight: 700 }}
          text={'Home'}
        />
      </a>

      <a style={linkStyle} href="https://anonymitybot.com/slack/faq/">
        <HeaderButton
          style={{ textTransform: 'none', fontSize: 16, fontWeight: 700 }}
          text={'FAQ'}
        />
      </a>
      <a style={linkStyle} href="https://anonymitybot.com/blog/">
        <HeaderButton
          style={{ textTransform: 'none', fontSize: 16, fontWeight: 700 }}
          text={'Blog'}
        />
      </a>
    </Fragment>
  );
};

const MenuBar = () => {
  const isSignedIn = useSelector((state) => state.auth.isSignedIn);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isSignedIn) {
    return <LoggedInMenuBar />;
  }
  return isMobile ? <MobileMenuBar /> : <RegularMenuBar />;
};

export default MenuBar;
