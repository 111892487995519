import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles({
  tooltip: {
    fontSize: 16,
    //backgroundColor: 'yellow',
  },
});
const NicerTooltip = withStyles({
  tooltip: {
    padding: 10,
    fontSize: 14,
    backgroundColor: 'white',
    //backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
    color: '#333333',
    opacity: 1,
    borderRadius: 10,
    fontFamily: 'Lato',
    boxShadow:
      'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
  },
  arrow: {
    color: 'white',
    //color: '#F5F5F5',
  },
})(Tooltip);

export default function InfoTooltip(props) {
  const { title, gutterBottom = false } = props;
  const classes = useStyles();

  return (
    <NicerTooltip
      arrow
      title={title}
      style={{ fontSize: '2em' }}
      classes={{ tooltip: classes.tooltip }}
      enterTouchDelay={0}
    >
      <InfoOutlinedIcon
        style={{
          height: '0.75em',
          width: '0.75em',
          marginBottom: gutterBottom ? '0.35em' : '0em',
          paddingLeft: '0.15em',
          color: '#333333',
        }}
      />
    </NicerTooltip>
  );
}
