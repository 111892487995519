import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SlackSvgIcon } from '../Common/SvgIcons';
import { isDev } from '../../misc/utils';
import { Redirect } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import art1 from '../assets/art1.svg';

const {
  REACT_APP_SLACK_CLIENT_ID,
  REACT_APP_SLACK_REDIRECT_URI_SIGNIN,
} = process.env;

const SigninButton = ({ marginLeft, upgrade }) => {
  const clientId = REACT_APP_SLACK_CLIENT_ID;
  const redirectURI = REACT_APP_SLACK_REDIRECT_URI_SIGNIN;
  const scope = 'identity.basic';
  let state = '';

  if (upgrade && isDev) {
    state = `local_dev,upgrade`;
  }

  if (upgrade && !isDev) {
    state = `upgrade`;
  }

  if (!upgrade && isDev) {
    state = `local_dev`;
  }

  return (
    <Button
      href={`https://slack.com/oauth/v2/authorize?user_scope=${scope}&client_id=${clientId}&redirect_uri=${redirectURI}&state=${state}`}
      style={{
        marginLeft,
        marginTop: '30px',
        marginBottom: '30px',
        borderRadius: 10,
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 16,
      }}
      size="large"
      color="primary"
      variant="contained"
    >
      <SlackSvgIcon />
      Sign-in with Slack
    </Button>
  );
};

const LeftPart = () => {
  return (
    <div style={{ textAlign: 'center', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Welcome back!
      </Typography>
      <Typography variant="h4" gutterBottom>
        {`Let's sign in.`}
      </Typography>

      <SigninButton />
    </div>
  );
};

const UpgradeLeftPart = () => {
  return (
    <div style={{ textAlign: 'center', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Upgrading? Great!
      </Typography>
      <Typography variant="h4" gutterBottom>
        {`Let's sign in.`}
      </Typography>
      <SigninButton upgrade />
    </div>
  );
};

export const dropMildPurpleShadow = ({ blurRadius = '30px', opacity = 0.3 }) =>
  `drop-shadow(12px 12px ${blurRadius} rgba(146,158,198,${opacity}))`;

const RightPart = () => {
  return (
    <Grid
      item
      key={2}
      style={{
        maxWidth: '600px',
        width: '100%',
        textAlign: 'center',
        padding: '10px',
      }}
    >
      <img
        src={art1}
        alt="AnonymityBot Art"
        style={{
          maxWidth: '600px',
          minWidth: '300px',
          // paddingLeft: '20px',
          // paddingRight: '20px',
          // https://stackoverflow.com/a/5219611
          boxSizing: 'border-box',
          width: '100%',
          filter: dropMildPurpleShadow({ blurRadius: '30px', opacity: 0.3 }),
        }}
      />
    </Grid>
  );
};

export default function SignIn() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [isUpgrade, setIsUpgrade] = React.useState(false);
  const isSignedIn = useSelector((state) => state.auth.isSignedIn);

  const isUpgradeInSearch = window.location.search === '?upgrade';

  React.useEffect(() => {
    if (isUpgradeInSearch) {
      setIsUpgrade(true);
    }
  }, []);

  if (isSignedIn) {
    const signedInTo = isUpgradeInSearch ? '/dashboard?upgrade' : '/dashboard';
    return <Redirect to={signedInTo} />;
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignContent="space-between"
      alignItems="center"
      direction="row"
      style={{
        paddingTop: matches ? '20px' : '40px',
        paddingBottom: matches ? '20px' : '40px',
        maxWidth: '900px',
        margin: '0 auto',
      }}
    >
      {isUpgrade ? <UpgradeLeftPart /> : <LeftPart />}
      <RightPart />
    </Grid>
  );
}
