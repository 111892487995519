import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CardProgress from '../../Common/CardProgress';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import {
  toggleWhispers,
  addToWhisperChannels,
  listenToWhisperChannels,
  removeFromWhisperChannels,
} from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import WhisperAsyncSelect from './WhisperAsyncSelect';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import { CardHeader, CardContent } from '@mui/material';
import InfoTooltip from './InfoTooltip';
import hashtag from '../../assets/hashtag.svg';
import lock from '../../assets/lock.svg';
import lightbulb from '../../assets/lightbulb.svg';
import ContainedButton from '../../Common/ContainedButton';

//import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    borderRadius: 10,
    textTransform: 'none',
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: 16,
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {},
  hashtagAvatar: {
    color: '#000',
    backgroundColor: '#fff',
  },
}));

const AddToWhitelistPanel = ({ disabled, inProgress }) => {
  const [selectedChannel, setSelectedChannel] = React.useState(null);

  const dispatch = useDispatch();
  return (
    <Grid direction="column" container spacing={2} style={{ maxWidth: 300 }}>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 400, display: 'inline' }}
        >
          Select a channel
        </Typography>
        <InfoTooltip
          gutterBottom
          title="Type the name of the private channel you want to enable anonymous whispers for and click on the action button below. You have to invite AnonymityBot to the private channel to be able to select it."
        />
      </Grid>
      <Grid item>
        <WhisperAsyncSelect
          disabled={disabled}
          setSelectedChannel={setSelectedChannel}
        />
      </Grid>
      <Grid item>
        <ContainedButton
          loading={inProgress}
          disabled={disabled}
          onClick={() => {
            if (selectedChannel) {
              // XXX what if selectedChannel is null?
              dispatch(addToWhisperChannels(selectedChannel));
            } else {
              // dispatch() a notif about selecting a channel.
            }
          }}
        >
          Add Channel
        </ContainedButton>
      </Grid>
    </Grid>
  );
};

const WhitelistListItem = ({ disabled, classes, name, id, isPrivate }) => {
  const dispatch = useDispatch();
  const onDeleteClick = () => {
    const channel = { id, name };
    dispatch(removeFromWhisperChannels(channel));
  };
  return (
    <ListItem disabled={disabled} disableGutters>
      <Avatar className={classes.hashtagAvatar}>
        <img src={isPrivate ? lock : hashtag} height="20" />
      </Avatar>
      <ListItemText
        primary={name}
        primaryTypographyProps={{ variant: 'subtitle2' }}
        style={{ marginRight: '20px' }}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={onDeleteClick}
          disabled={disabled}
          edge="end"
          aria-label="delete"
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
const WhisperChannelsListPanel = ({ disabled, classes }) => {
  const whisperChannels = useSelector(
    (state) => state.database.whisperChannels.items
  );
  return (
    <Grid direction="column" container spacing={2} style={{ maxWidth: 300 }}>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 400, display: 'inline' }}
        >
          Whisperable Channels
        </Typography>
        <InfoTooltip
          gutterBottom
          title="A user who is not a member of a private channel can't send anonymous messages to it. Click on the bin icon to delete a channel from the whitelist."
        />
      </Grid>
      <Grid item>
        <List dense={false} disablePadding>
          {!whisperChannels.length && (
            <Typography variant="body2">
              {' '}
              No whisper channels were added yet
            </Typography>
          )}
          {whisperChannels.map((channel, i) => (
            <WhitelistListItem
              key={i}
              disabled={disabled}
              classes={classes}
              name={channel.name}
              id={channel.id}
              isPrivate={channel.is_private || null}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

const WhisperPanel = ({ teamData, toggleWhispers, inProgress }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  React.useEffect(() => {
    const unsubscribe = dispatch(listenToWhisperChannels());
    return unsubscribe;
  }, []);
  const classes = useStyles();

  const isWhispersEnabled = !!teamData['whisper_channels_enabled'];
  const [state, setState] = React.useState({
    checkedA: isWhispersEnabled,
  });

  const handleChange =
    (name) =>
    async ({ target: { checked } }) => {
      try {
        await toggleWhispers(checked);
        setState({ ...state, [name]: checked });
      } catch (err) {
        console.log(err);
      }
    };

  const disabledSwitch = inProgress || !isAdmin;
  const disabledRestOfPanel = disabledSwitch || !state.checkedA;
  return (
    <CardProgress inProgress={inProgress}>
      <CardHeader
        style={{ padding: '16px 24px' }}
        title={
          <div
            style={{
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{
                display: 'inline-flex',
                justifyContent: 'start',
              }}
              gutterBottom
            >
              Anonymous Whispers
            </Typography>
            <Switch
              style={{ justifyContent: 'end' }}
              checked={state.checkedA}
              onChange={handleChange('checkedA')}
              color="primary"
              disabled={disabledSwitch}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />

            {/*!isAdmin && <Chip variant="outlined" label="Not Admin" />*/}
          </div>
        }
        subheader={`BETA. Allow anonymous messages from your team to a private channel ("whispers"). Made for private suggestions box, sensitive issues or raising concerns that shouldn't be shared with everyone`}
        subheaderTypographyProps={{
          style: { fontSize: '14px', fontWeight: 400, color: '#546e7a' },
        }}
      />
      <Divider />

      <CardContent style={{ padding: '16px 24px' }}>
        <Grid
          container
          spacing={isMobile ? 0 : 4}
          direction={isMobile ? 'column' : 'row'}
        >
          <Grid item>
            <AddToWhitelistPanel
              disabled={disabledRestOfPanel}
              inProgress={inProgress}
            />
          </Grid>
          {isMobile && (
            <Grid item style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Divider />
            </Grid>
          )}
          <Grid item>
            <WhisperChannelsListPanel
              disabled={disabledRestOfPanel}
              classes={classes}
            />
          </Grid>
        </Grid>
        <Typography style={{ fontSize: 12, marginTop: 20 }}>
          <img
            style={{ verticalAlign: 'middle' }}
            src={lightbulb}
            height="20"
          />
          <span style={{ fontWeight: 'bold' }}>Tip: </span>
          Invite AnonymityBot to the private channel and then select it from the
          list above.
        </Typography>
      </CardContent>
    </CardProgress>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.error || state.database.error,
  inProgress: state.database.features.whisperChannels.inProgress,
  isSignedIn: state.auth.isSignedIn,
  userData: state.database.user.data,
  teamData: state.database.team.data,
});

const mapDispatchToProps = { toggleWhispers };

export default connect(mapStateToProps, mapDispatchToProps)(WhisperPanel);
