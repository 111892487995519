import React from 'react';
import GodPanel from './Dashboard/GodPanel';
import { Grid } from '@mui/material';
//import { makeStyles } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import CardProgress from '../Common/CardProgress';
import LoadingPage from '../Common/LoadingPage';
import * as actions from '../../actions';
import { TeamInfo } from './Dashboard';
import UpgradePlanButton from './UpgradePlanButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContainedButton from '../Common/ContainedButton';

import { useDispatch, useSelector } from 'react-redux';

const Transaction = (props) => {
  const { created_at, amount, receipt_url } = props.transactionData;
  let [month, date, year] = new Date(created_at)
    .toLocaleDateString('en-US')
    .split('/');
  return <>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item style={{ paddingTop: 12 }}>
        <Typography
          variant="body1"
          style={{ marginTop: 12, fontWeight: 400, display: 'inline' }}
        >
          {`${year}-${month}-${date}`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          style={{ fontWeight: 400, display: 'inline', color: 'grey' }}
        >
          {`$${amount}`}
        </Typography>
        <IconButton target="_blank" rel="noreferrer" href={receipt_url} size="large">
          <GetAppIcon />
        </IconButton>
      </Grid>
    </Grid>
  </>;
};

const SubscriptionDetails = () => {
  //const classes = useStyles();
  const subscriptionDetails = useSelector(
    (state) => state.paddle.subscription_details
  );
  const dispatch = useDispatch();
  React.useEffect(async () => {
    if (!subscriptionDetails.inProgress || !subscriptionDetails.success) {
      dispatch(actions.getSubscriptionDetails());
    }
  }, []);

  const handleClick = (overrideUrl) => {
    window.Paddle.Checkout.open({
      override: overrideUrl,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleEditClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditClose = () => {
    setAnchorEl(null);
  };

  return (
    <CardProgress inProgress={subscriptionDetails.inProgress}>
      <CardHeader
        style={{ padding: '16px 24px' }}
        title={
          <div>
            <Typography
              variant="h5"
              style={{
                display: 'inline-flex',
                justifyContent: 'start',
              }}
              gutterBottom
            >
              Payment Details
            </Typography>
          </div>
        }
      />
      <Divider />

      <CardContent
        style={{
          padding: '16px 24px',
        }}
      >
        {!subscriptionDetails.success ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <>
            <Typography variant="body1" style={{ fontWeight: 400 }}>
              {`•••• •••• •••• ${subscriptionDetails.data[0].payment_information.last_four_digits}`}
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: 400, color: 'grey' }}
            >
              Expires{' '}
              {subscriptionDetails.data[0].payment_information.expiry_date}
            </Typography>
            <br />

            <Typography
              variant="body2"
              style={{ fontWeight: 400, color: 'grey' }}
            >
              You are billed monthly.
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: 400, color: 'grey' }}
            >
              Next payment on {subscriptionDetails.data[0].next_payment.date}.
            </Typography>

            {/*
            <Typography variant="body1" style={{ fontWeight: 400 }}>
              Registration email
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 400 }}>
              {subscriptionDetails.data[0].user_email}
            </Typography>

            <Typography>{subscriptionDetails.data[0].signup_date}</Typography>
            <Typography>
              {subscriptionDetails.data[0].payment_information.card_type}
            </Typography>
 
            <Typography>
              {subscriptionDetails.data[0].payment_information.card_type}
            </Typography>
            <Typography>{subscriptionDetails.data[0].state}</Typography>
            */}
          </>
        )}
      </CardContent>
      <CardActions>
        <Button
          size="large"
          color="primary"
          style={{ textTransform: 'none', fontWeight: 700 }}
          onClick={handleEditClick}
          disabled={subscriptionDetails.inProgress}
        >
          Edit
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleEditClose}
        >
          <MenuItem
            onClick={() => {
              handleEditClose();
              handleClick(subscriptionDetails.data[0].update_url);
            }}
          >
            Update Payment Details
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleEditClose();
              handleClick(subscriptionDetails.data[0].cancel_url);
            }}
          >
            Cancel Subscription
          </MenuItem>
        </Menu>
      </CardActions>
    </CardProgress>
  );
};

const Invoices = () => {
  //const classes = useStyles();
  const transactions = useSelector((state) => state.paddle.transactions);
  const dispatch = useDispatch();
  React.useEffect(async () => {
    if (!transactions.inProgress || !transactions.success) {
      dispatch(actions.getTransactions());
    }
  }, []);

  return (
    <CardProgress inProgress={transactions.inProgress}>
      <CardHeader
        style={{ padding: '16px 24px' }}
        title={
          <div>
            <Typography
              variant="h5"
              style={{
                display: 'inline-flex',
                justifyContent: 'start',
              }}
              gutterBottom
            >
              Invoices
            </Typography>
          </div>
        }
      />
      <Divider />

      <CardContent
        style={{
          padding: '16px 24px',
        }}
      >
        {!transactions.success ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          transactions.data.map((transactionData, i) => (
            <Transaction key={i} transactionData={transactionData} />
          ))
        )}
      </CardContent>
    </CardProgress>
  );
};

const NotSubscribed = () => (
  <Grid
    container
    direction="row"
    justifyContent="center"
    spacing={2}
    style={{
      flex: 1,
      maxWidth: '1024px',
      paddingLeft: 10,
      paddingRight: 10,
      margin: '0 auto',
      width: '100%',
      textAlign: 'center',
    }}
  >
    <Grid item xs={12} sm={12}>
      <GodPanel />
    </Grid>

    <Grid item container xs={12} sm={12} direction="column">
      <Grid item>
        <Typography variant="h4">👋 Hey there!</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          You are not a paying customer yet...
        </Typography>
      </Grid>
      <Grid item>
        <UpgradePlanButton />
      </Grid>
    </Grid>
  </Grid>
);

const NotAdmin = () => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    spacing={2}
    style={{
      maxWidth: '1024px',
      paddingLeft: 10,
      paddingRight: 10,
      margin: '0 auto',
      width: '100%',
      textAlign: 'center',
    }}
  >
    <Grid item xs={12} sm={12}>
      <GodPanel />
    </Grid>

    <Grid item>
      <Typography variant="h4">👋 Hey there!</Typography>
    </Grid>
    <Grid item>
      <Typography variant="h6">
        Only Slack admins can view the billing information...
      </Typography>
    </Grid>
    <Grid item>
      <ContainedButton href="/dashboard">
        <DashboardIcon style={{ marginRight: 8 }} />
        Dashboard
      </ContainedButton>
    </Grid>
  </Grid>
);

const BillingPage = () => {
  const dispatch = useDispatch();
  const { teamId } = useSelector((state) => state.auth);
  const isSignedIn = useSelector((state) => state.auth.isSignedIn);
  const inProgress = useSelector(
    (state) =>
      !state.auth.isInitialized ||
      state.auth.inProgress ||
      state.database.team.inProgress ||
      !state.database.team.success
  );
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  React.useEffect(() => {
    if (isSignedIn) {
      dispatch(actions.listenToTeamData({ teamId }));
    }
  }, [isSignedIn, teamId]);

  const subscriptionId = useSelector(
    (state) =>
      state.database.team.data.paddle &&
      state.database.team.data.paddle.subscription_id
  );

  if (inProgress) {
    return <LoadingPage />;
  }
  if (!subscriptionId) {
    return <NotSubscribed />;
  }
  if (!isAdmin) {
    return <NotAdmin />;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      spacing={2}
      style={{
        flex: 1,
        maxWidth: '1024px',
        paddingLeft: 10,
        paddingRight: 10,
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Grid item xs={12} sm={12}>
        <GodPanel />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TeamInfo />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SubscriptionDetails />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Invoices />
      </Grid>
    </Grid>
  );
};
export default BillingPage;
