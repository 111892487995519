import React from 'react';
import Lottie from 'react-lottie';
import Typography from '@mui/material/Typography';
import { AddToSlackButtonWhiteX } from './AddToButtons';

const animationData = require('../assets/error_smiley.json');

class Failure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
      isPaused: false,
    };
  }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        <div style={{ textAlign: 'center', margin: '10px' }}>
          <Typography variant="h4" gutterBottom>
            Ooops
          </Typography>
          <Typography
            variant="h5"
            color="textSecondary"
            gutterBottom
            style={{ marginBottom: '20px' }}
          >
            Something went wrong! Please try again or reach out to us!
          </Typography>
          <AddToSlackButtonWhiteX />
        </div>
      </div>
    );
  }
}

export default Failure;
