import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import TranslateIcon from '@mui/icons-material/Translate';
import languages from '@sagi.io/google-translate-languages';
import InfoTooltip from '../Main/Dashboard/InfoTooltip';

const distinctLanguages = [...new Set(Object.values(languages))];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(lang, shownLanguages, theme) {
  return {
    fontWeight:
      shownLanguages.indexOf(lang) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({
  isDisabled,
  stateValues,
  handleAllowedLanguagesSelectChange,
}) {
  const theme = useTheme();
  const [shownLanguages, setShownLanguages] = React.useState(stateValues);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log({ value });
    setShownLanguages(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    handleAllowedLanguagesSelectChange(value);
  };

  return (
    <div>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontWeight: 400, display: 'inline' }}
          >
            Allowed languages
          </Typography>
          <InfoTooltip title="Limit anonymous messages to specific languages" />
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 0, width: 220 }}>
            <InputLabel id="demo-multiple-chip-label">Languages</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              disabled={isDisabled}
              displayEmpty
              value={shownLanguages}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <TranslateIcon />
                </InputAdornment>
              }
              input={
                <OutlinedInput id="select-multiple-chip" label="Languages" />
              }
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Choose language(s)</em>;
                }

                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        style={{ height: 'auto' }}
                      />
                    ))}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {distinctLanguages.map((lang) => (
                <MenuItem
                  dense
                  key={lang}
                  value={lang}
                  style={getStyles(lang, shownLanguages, theme)}
                >
                  <Checkbox checked={shownLanguages.indexOf(lang) > -1} />
                  <ListItemText primary={lang} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
