/* eslint-disable */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { TeamMetric } from './Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import * as actions from '../../actions';
import ContainedButton from '../Common/ContainedButton';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Lottie from 'react-lottie';
const animationData = require('../assets/email_with_success.json');
import { isDev } from '../../misc/utils';
//import { Typography } from '@mui/material';

const useStyles = makeStyles({
  paper: {
    borderRadius: 10,
  },
});

export const getPlan = (nrActiveUsers) => {
  let [plan, productId, cost, isEnterprise] = ['', 0, 0, false];

  if (nrActiveUsers >= 1 && nrActiveUsers <= 50) {
    plan = 'Business 1 - 50 Members';
    productId = isDev ? 10962 : 684335;
    cost = 49;
  }

  if (nrActiveUsers >= 51 && nrActiveUsers <= 150) {
    plan = 'Business 51 - 150 Members';
    productId = isDev ? 15521 : 684233;
    cost = 99;
  }

  if (nrActiveUsers >= 151 && nrActiveUsers <= 300) {
    plan = 'Business 151 - 300 Members';
    productId = isDev ? 15522 : 684236;
    cost = 149;
  }

  if (nrActiveUsers >= 301 && nrActiveUsers <= 500) {
    plan = 'Business 301 - 500 Members';
    productId = isDev ? 15523 : 684243;
    cost = 199;
  }

  if (nrActiveUsers >= 501 && nrActiveUsers <= 750) {
    plan = 'Business 501 - 750 Members';
    productId = isDev ? 17478 : 738572;
    cost = 299;
  }

  if (nrActiveUsers >= 751 && nrActiveUsers <= 1000) {
    plan = 'Business 751 - 1000 Members';
    productId = isDev ? 17477 : 738571;
    cost = 399;
  }

  if (nrActiveUsers >= 1001) {
    plan = `Enterprise ${nrActiveUsers} Members`;
    isEnterprise = true;
    cost = (nrActiveUsers * 0.4).toFixed(2);
  }

  return { plan, cost, productId, isEnterprise };
};

export default function FormDialog(props) {
  const { toOpen = false, setIsDialogOpen } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setOpen(toOpen);
    setIsDialogOpen && setIsDialogOpen(toOpen);
  }, [toOpen]);

  const handleClickOpen = () => {
    setOpen(true);
    setIsDialogOpen && setIsDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsDialogOpen && setIsDialogOpen(false);
  };

  const teamData = useSelector((state) => state.database.team.data);
  const authData = useSelector((state) => state.auth);
  const { teamId, userId, email, isAdmin, displayName } = authData;
  const { enterprise_id: enterpriseId, enterpriseName: enterpriseName } =
    teamData;
  const passthrough = JSON.stringify({
    type: 'slack',
    teamId,
    userId,
    email,
    isAdmin,
    displayName,
    enterpriseId,
    enterpriseName,
  });

  const {
    nr_users: nrUsers,
    nr_single_channel_guests: nrSingleChannelGuests = 0,
    /*
    team_name: teamName,
    team_domain: teamDomain,
    team_icon_url: teamIconUrl,
    last_post_register_time: lastPostRegisterTime,
    last_refresh_users_time: lastRefreshUsersTime,
    */
  } = teamData;

  const nrActiveUsers = nrUsers - nrSingleChannelGuests;
  const { plan, cost, productId, isEnterprise } = getPlan(nrActiveUsers);
  const successCallback = () => {
    setSuccess(true);
  };

  const handlePurchase = async () => {
    setLoading(true);
    if (isEnterprise) {
      const overrideUrl = await dispatch(actions.getEnterprisePaymentLink());
      window.Paddle.Checkout.open({
        override: overrideUrl,
        passthrough,
        successCallback,
      });
    } else {
      window.Paddle.Checkout.open({
        product: productId,
        passthrough,
        successCallback,
      });
    }
    setLoading(false);
  };

  const classes = useStyles();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <ContainedButton onClick={handleClickOpen}>
        <CreditCardIcon style={{ marginRight: '8px' }} />
        Upgrade Plan
      </ContainedButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        style={{ borderRadius: 10 }}
        classes={{ paper: classes.paper }}
        disableEnforceFocus
      >
        {success ? (
          <>
            <DialogTitle id="form-dialog-title">Welcome aboard!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`We have emailed you the details of your order.`}
              </DialogContentText>

              <Lottie
                //style={{ position: 'relative', top: '-50px' }}
                options={defaultOptions}
                height={200}
                width={'100%'}
                isStopped={false}
                isPaused={false}
                eventListeners={[
                  {
                    //eventName: 'complete',
                    //callback: () => setOpen(false),
                  },
                ]}
              />
            </DialogContent>
            <DialogActions style={{ padding: '8px 24px' }}>
              <ContainedButton onClick={handleClose} color="primary">
                Close
              </ContainedButton>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="form-dialog-title">Upgrade Plan</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ paddingBottom: 10 }}>
                {`Enable all AnonymityBot's features! See our `}
                <a
                  rel="noreferrer"
                  target="_blank"
                  id="anon-link"
                  href="https://anonymitybot.com/pricing"
                >
                  pricing page
                </a>
                .
              </DialogContentText>
              <TeamMetric
                MetricIcon={PeopleAltIcon}
                backgroundColor="#68c3a3"
                label="Users"
                labelData={nrUsers - nrSingleChannelGuests}
                tooltipText="The number of users, including multi-channel guests, not including single-channel guests and bots."
              />
              <TeamMetric
                MetricIcon={LocalOfferIcon}
                backgroundColor="#68c3a3"
                label="Plan"
                labelData={plan}
                tooltipText={`All plans enable all AnonymityBot's features. Plans differ only by the number of users.`}
              />
              <TeamMetric
                MetricIcon={CreditCardIcon}
                backgroundColor="#68c3a3"
                label="Monthly Cost"
                labelData={`$` + cost}
                tooltipText={
                  'Business plans comes at fixed monthly price. Enterprise plans (over 1000 members) are $0.4 per person per month. If you have any questions, please contact us at hey@anonymitybot.com.'
                }
              />
              {/*
          <DialogContentText style={{ paddingTop: 20 }}>
            Questions, suggestions or thoughts?{' '}
            <a
              rel="noreferrer"
              target="_blank"
              id="anon-link"
              href="mailto:hey@anonymitybot.com"
            >
              hey@anonymitybot.com
            </a>
            .
          </DialogContentText>
          */}
            </DialogContent>
            <DialogActions style={{ padding: '8px 24px' }}>
              <Button
                onClick={handleClose}
                color="primary"
                style={{ textTransform: 'none' }}
              >
                Cancel
              </Button>
              <ContainedButton
                variant="contained"
                onClick={handlePurchase}
                color="primary"
                loading={loading}
                disabled={loading}
              >
                Purchase
              </ContainedButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
