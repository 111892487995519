import { createTheme, adaptV4Theme } from '@mui/material/styles';

const theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: ['Lato'],
    },
    /* XXX Leaving here so you know this is possible
  typography: {
    h5: {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  */
    // linear-gradient(90deg,#b87df9,#6a4ee1)
    palette: {
      mode: 'light',
      primary: {
        main: '#76719f', //'#6D6DAA', //'#a9a2e4',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#f50057',
      },
    },
    overrides: {
      MuiPaper: {
        elevation1: {
          boxShadow:
            'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
        },
      },
    },
  })
);

export default theme;
