/* eslint-disable */
import React from 'react';
import {
  Typography,
  Divider,
  CardHeader,
  CardContent,
  SvgIcon,
} from '@mui/material';
import CardProgress from '../../Common/CardProgress';
import { useDispatch, useSelector } from 'react-redux';
import { listenToMonthlyUsage } from '../../../actions';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loading from '../../Common/Loading';
import Grid from '@mui/material/Grid';
//import useMediaQuery from '@mui/material/useMediaQuery';
//import { useTheme } from '@mui/material/styles';
import ChatIcon from '@mui/icons-material/Chat';
import ForumIcon from '@mui/icons-material/Forum';
import PollIcon from '@mui/icons-material/Poll';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FlagIcon from '@mui/icons-material/Flag';
import HearingIcon from '@mui/icons-material/Hearing';
import InfoTooltip from './InfoTooltip';
import PolicyIcon from '@mui/icons-material/Policy';

export function HomeIcon(props) {
  return (
    <SvgIcon viewBox="0 0 256 256" {...props}>
      <path
        d="M191.982 68.687A26.029 26.029 0 0 0 148.8 65.89l-13.993-24.237a25.99 25.99 0 0 0-43.88-1.81A26.02 26.02 0 0 0 47.5 63.333a25.997 25.997 0 0 0-16.616 38.32l1.992 3.451a26.032 26.032 0 0 0-19.17 38.798l37 64.086a80.003 80.003 0 0 0 144.213-11.97a79.99 79.99 0 0 0 14.064-97.886zm-84.694-22.694a10.013 10.013 0 0 1 13.662 3.66L142.3 86.631a26.154 26.154 0 0 0-13.213 9.116L115.093 71.51a25.84 25.84 0 0 0-10.38-9.977l-1.084-1.88a10.012 10.012 0 0 1 3.66-13.66zm-39.176 4.144a10.012 10.012 0 0 1 13.66 3.66l3.353 5.808a25.914 25.914 0 0 0-13.92 10.105a25.873 25.873 0 0 0-5.668-4.034l-1.085-1.878a10.012 10.012 0 0 1 3.66-13.661zm83.876 173.276a64.074 64.074 0 0 1-87.426-23.425l-37-64.085a10 10 0 0 1 17.322-10l24 41.569a8 8 0 0 0 13.856-8l-38-65.819a10 10 0 1 1 17.32-10l38 65.819a8 8 0 0 0 13.856-8l-30-51.962a10 10 0 1 1 17.321-10l42 72.746a8 8 0 0 0 13.856-8l-16-27.712v-.001a10 10 0 0 1 17.321-10l17 29.445a64.001 64.001 0 0 1-23.426 87.425zm47.66-63.039a79.747 79.747 0 0 0-10.377-32.386L172.27 98.543a25.819 25.819 0 0 0-10.394-10.003l-1.07-1.853a10 10 0 1 1 17.32-10l17 29.445a64.273 64.273 0 0 1 4.522 54.242zm-34.67-135.72L169.119 9.2a8 8 0 0 1 15.455 4.143l-4.141 15.454a8 8 0 0 1-15.455-4.143zm26.355 9.225l9.178-13.107a8 8 0 1 1 13.105 9.178l-9.178 13.107a8 8 0 0 1-13.105-9.178zm43.895 21.61l-13.107 9.178a8 8 0 0 1-9.178-13.105l13.107-9.178a8 8 0 1 1 9.178 13.105z"
        fill="white"
      />
    </SvgIcon>
  );
}

function MegaIcon(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M14.158 1.026a.5.5 0 0 1 .317.632l-.5 1.5a.5.5 0 0 1-.95-.316l.5-1.5a.5.5 0 0 1 .633-.316zm3.697 1.828a.5.5 0 1 0-.708-.708l-2 2a.5.5 0 0 0 .707.708l2-2zm-10.248.291a2 2 0 0 1 3.261-.514l6.587 6.98a2 2 0 0 1-.648 3.203L12.87 14.55a3.504 3.504 0 0 1-3.37 4.45a3.498 3.498 0 0 1-2.975-1.655l-1.2.528a1.5 1.5 0 0 1-1.661-.307l-1.222-1.211a1.5 1.5 0 0 1-.299-1.71L7.607 3.146zm-.154 13.79a2.5 2.5 0 0 0 4.488-1.977l-4.488 1.977zM17 6a.5.5 0 0 0 0 1h1.5a.5.5 0 1 0 0-1H17z"
        fill="white"
      />
    </SvgIcon>
  );
}

const sortMonthYear = (a, b) => {
  if (b.year_month === 'Total Usage') {
    return 1;
  }
  const aDate = Date.parse(`${a.year_month}-01`);
  const bDate = Date.parse(`${b.year_month}-01`);
  return bDate - aDate;
};

const allUsageData = (monthlyUsage) => {
  const all = {};
  monthlyUsage.forEach((month) => {
    Object.entries(month).forEach(
      ([k, v]) => (all[k] = all[k] ? all[k] + v : v)
    );
  });
  all.year_month = 'Total Usage';
  return all;
};
const UsageCardContent = () => {
  const monthlyUsage = useSelector(
    (state) => state.database.monthly_usage.items
  ).sort(sortMonthYear);

  if (
    monthlyUsage.length === 0 ||
    monthlyUsage.filter(({ year_month }) => year_month === 'Total Usage')
      .length === 0
  ) {
    monthlyUsage.unshift(allUsageData(monthlyUsage));
  }

  const [usageData, setUsageData] = React.useState(monthlyUsage[0]);
  React.useEffect(() => setUsageData(monthlyUsage[0]), [monthlyUsage]);

  const isNoUsage = monthlyUsage.length === 1; // i.e. only the "Total Usage" is there.

  const nadjaPallete = [
    '#6D6DAA',
    '#A9A2E4',
    '#929EC6',
    '#97C1FF',
    '#90c695',
    '#f1a9a0',
    '#e08283',
    //'#f7ca18',
    '#f5d76e',
    '#abb7b7',
  ];
  //const slackPallete = ['#5c3a58', '#e6186d', '#edb625', '#49c4a1'];
  const pallete = nadjaPallete;

  const {
    anonymous_message = 0,
    anonymous_reply = 0,
    anonymous_poll = 0,
    anonymous_vote = 0,
    anonymous_upvote = 0,
    anonymous_karma = 0,
    flag_report = 0,
    anonymous_whisper = 0,
    anonymous_praise = 0,
    text_analysis = 0,
  } = usageData;

  const data = {
    labels: [
      'Messages',
      'Replies',
      'Polls',
      'Votes',
      'Karma',
      'Flag Reports',
      'Whispers',
      'Shoutouts',
      'Analyses',
    ],
    datasets: [
      {
        data: [
          anonymous_message,
          anonymous_reply,
          anonymous_poll,
          anonymous_vote,
          anonymous_karma + anonymous_upvote,
          flag_report,
          anonymous_whisper,
          anonymous_praise,
          text_analysis,
        ],
        backgroundColor: pallete,
        borderWidth: 2,
      },
    ],
  };
  const noUsageData = {
    datasets: [
      {
        data: [1],
      },
    ],
  };

  const handleChange = (x) => {
    const selectedYearMonth = x.target.value;
    for (const monthUsage of monthlyUsage) {
      if (monthUsage.year_month === selectedYearMonth) {
        setUsageData(monthUsage);
      }
    }
  };

  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <CardContent
      style={{
        padding: '16px 0px',
        //paddingLeft: '24px',
        margin: '0 auto',
      }}
    >
      <Grid alignItems="center" direction="column" container spacing={3}>
        <Grid item>
          <FormControl variant="outlined">
            <InputLabel>Month</InputLabel>
            <Select
              onChange={handleChange}
              value={usageData.year_month}
              label="Month"
            >
              {monthlyUsage.map((monthData, i) => (
                <MenuItem key={i} value={monthData.year_month}>
                  {monthData.year_month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Doughnut
            data={isNoUsage ? noUsageData : data}
            width={200}
            height={200}
            options={{
              tooltips: {
                enabled: isNoUsage ? false : true,
              },
              //responsive: true,
              cutout: 80,
              maintainAspectRatio: false,
              //circumference: Math.PI,
              //rotation: -Math.PI,
              plugins: {
                legend: { display: false },
                datalabels: {
                  display: false,
                  color: 'white',
                  font: {
                    family: 'Lato',
                    weight: 'bold',
                    size: 14,
                  },
                  formatter: Math.round,
                },
              },
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%', margin: '0 auto' }}>
          <Grid
            container
            direction="column"
            spacing={2}
            justifyContent="center"
          >
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={ChatIcon}
                  backgroundColor={nadjaPallete[0]}
                  label={'Messages'}
                  count={usageData.anonymous_message || 0}
                  strictWidth
                  tooltipText="Number of anonymous messages in public and private channels."
                />
              </Grid>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={ForumIcon}
                  backgroundColor={nadjaPallete[1]}
                  label={'Replies'}
                  count={usageData.anonymous_reply || 0}
                  strictWidth
                  tooltipText="Number of anonymous replies (to regular and anonymous messages) in public and private channels."
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={PollIcon}
                  backgroundColor={nadjaPallete[2]}
                  label={'Polls'}
                  count={usageData.anonymous_poll || 0}
                  strictWidth
                  tooltipText="Number of polls in public and private channels."
                />
              </Grid>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={HowToVoteIcon}
                  backgroundColor={nadjaPallete[3]}
                  label={'Votes'}
                  count={usageData.anonymous_vote || 0}
                  tooltipText="Number of votes - i.e. a selection of a poll option. Selecting a different vote counts as a new vote."
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={FavoriteIcon}
                  backgroundColor={nadjaPallete[4]}
                  label={'Karma'}
                  count={
                    (usageData.anonymous_karma || 0) +
                    (usageData.anonymous_upvote || 0)
                  }
                  strictWidth
                  tooltipText="Number of Karma votes in anonymous messages, replies, polls and whispers."
                />
              </Grid>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={FlagIcon}
                  backgroundColor={nadjaPallete[5]}
                  label={'Flags'}
                  count={usageData.flag_report || 0}
                  tooltipText="Number of flag reports for anonymous messages and replies. Selecting a different flag reason counts as a new flag report."
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={HearingIcon}
                  backgroundColor={nadjaPallete[6]}
                  label={'Whispers'}
                  count={usageData.anonymous_whisper || 0}
                  strictWidth
                  tooltipText="Number of anonymous whispers."
                />
              </Grid>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={MegaIcon}
                  backgroundColor={nadjaPallete[7]}
                  label={'Shoutouts'}
                  count={usageData.anonymous_praise || 0}
                  tooltipText="Number of anonymous shoutouts."
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item>
                <AnonymousInteractionCount
                  InteractionIcon={PolicyIcon}
                  backgroundColor={nadjaPallete[8]}
                  label={'Analyses'}
                  count={usageData.text_analysis || 0}
                  strictWidth
                  tooltipText="Number of sentiment and perspective analyses done on messages."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

const NoUsageYet = () => (
  <CardContent
    style={{
      padding: '16px 24px',
      textAlign: 'center',
    }}
  >
    <Typography variant="body2"> No usage yet...</Typography>
  </CardContent>
);

const AnonymousInteractionCount = ({
  InteractionIcon,
  backgroundColor,
  label,
  count,
  strictWidth = false,
  tooltipText,
}) => {
  return (
    <Grid spacing={0} direction="row" container>
      <Grid item style={{ paddingRight: 8 }}>
        <InteractionIcon
          style={{
            size: 40,
            color: 'white',
            borderRadius: 28,
            backgroundColor,
            padding: 8,
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
          }}
        />
      </Grid>
      <Grid
        item
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          minWidth: strictWidth ? 80 : undefined,
        }}
      >
        <Typography
          style={{
            fontSize: '13px',
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, 0.87)',
            display: 'inline',
          }}
        >
          {label}
        </Typography>
        <InfoTooltip title={tooltipText} />
        <Typography
          style={{ fontSize: '14px', fontWeight: 300, color: '#333333' }}
        >
          {' '}
          {count}
        </Typography>
      </Grid>
    </Grid>
  );
};
const LoadingCardContent = () => (
  <CardContent style={{ padding: '16px 24px' }}>
    <Loading />{' '}
  </CardContent>
);

const UsageCardWrapper = ({ inProgress, children }) => (
  <CardProgress inProgress={inProgress}>
    <CardHeader
      style={{ padding: '16px 24px' }}
      title={
        <div>
          <Typography
            variant="h5"
            style={{
              display: 'inline-flex',
              justifyContent: 'start',
            }}
            gutterBottom
          >
            Monthly Usage
          </Typography>
          {/*!isAdmin && <Chip variant="outlined" label="Not Admin" />*/}
        </div>
      }
      subheader="Monthly usage of anonymous interactions"
      subheaderTypographyProps={{
        style: { fontSize: '14px', fontWeight: 400, color: '#546e7a' },
      }}
    />
    <Divider />
    {children}
  </CardProgress>
);

const Usage = () => {
  const dispatch = useDispatch();
  // const teamData = useSelector((state) => state.database.team.data);
  const inProgressOrNotYetCalled = useSelector(
    (state) =>
      state.database.monthly_usage.inProgress ||
      !state.database.monthly_usage.success
  );
  // const isAdmin = useSelector((state) => state.auth.isAdmin);
  // const storedTeamConfig = getConfigFromTeamData(teamData);
  // const [state] = React.useState(storedTeamConfig);
  React.useEffect(() => {
    const unsubscribe = dispatch(listenToMonthlyUsage());
    return unsubscribe;
  }, []);
  if (inProgressOrNotYetCalled) {
    return (
      <UsageCardWrapper inProgress={inProgressOrNotYetCalled}>
        <LoadingCardContent />
      </UsageCardWrapper>
    );
  }

  if (!inProgressOrNotYetCalled) {
    return (
      <UsageCardWrapper inProgress={inProgressOrNotYetCalled}>
        <UsageCardContent />
      </UsageCardWrapper>
    );
  }

  //const isDisabled = !isAdmin || inProgress;
};

export default Usage;
