import React from 'react';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import hangouts_logo from '../assets/hangouts_chat.svg';
import { SlackSvgIcon } from '../Common/SvgIcons';

export const AddToSlackButton = () => (
  <div style={{ marginTop: '20px' }}>
    <a href="https://slack.com/oauth/authorize?scope=commands,bot&client_id=392932202482.392977393284">
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  </div>
);

export const AddToSlackButtonColor = () => {
  return (
    <Button
      variant="contained"
      style={{ backgroundColor: 'white', color: 'black' }}
    >
      <SvgIcon viewBox="0 0 256 256" style={{ marginRight: 5 }}>
        <path
          d="M165.964 15.838c-3.89-11.975-16.752-18.528-28.725-14.636-11.975 3.89-18.528 16.752-14.636 28.725l58.947 181.365c4.048 11.187 16.132 17.473 27.732 14.135 12.1-3.483 19.475-16.334 15.614-28.217L165.964 15.838"
          fill="#DFA22F"
        />
        <path
          d="M74.626 45.516C70.734 33.542 57.873 26.989 45.9 30.879 33.924 34.77 27.37 47.631 31.263 59.606l58.948 181.366c4.047 11.186 16.132 17.473 27.732 14.132 12.099-3.481 19.474-16.332 15.613-28.217L74.626 45.516"
          fill="#3CB187"
        />
        <path
          d="M240.162 166.045c11.975-3.89 18.526-16.75 14.636-28.726-3.89-11.973-16.752-18.527-28.725-14.636L44.708 181.632c-11.187 4.046-17.473 16.13-14.135 27.73 3.483 12.099 16.334 19.475 28.217 15.614l181.372-58.93"
          fill="#CE1E5B"
        />
        <path
          d="M82.508 217.27l43.347-14.084-14.086-43.352-43.35 14.09 14.089 43.347"
          fill="#392538"
        />
        <path
          d="M173.847 187.591c16.388-5.323 31.62-10.273 43.348-14.084l-14.088-43.36-43.35 14.09 14.09 43.354"
          fill="#BB242A"
        />
        <path
          d="M210.484 74.706c11.974-3.89 18.527-16.751 14.637-28.727-3.89-11.973-16.752-18.526-28.727-14.636L15.028 90.293C3.842 94.337-2.445 106.422.896 118.022c3.481 12.098 16.332 19.474 28.217 15.613l181.371-58.93"
          fill="#72C5CD"
        />
        <path
          d="M52.822 125.933c11.805-3.836 27.025-8.782 43.354-14.086-5.323-16.39-10.273-31.622-14.084-43.352l-43.36 14.092 14.09 43.346"
          fill="#248C73"
        />
        <path
          d="M144.16 96.256l43.356-14.088a546179.21 546179.21 0 0 0-14.089-43.36L130.07 52.9l14.09 43.356"
          fill="#62803A"
        />
      </SvgIcon>
      Add to Slack
    </Button>
  );
};

export const AddToSlackButtonWhite = () => {
  return (
    <Button
      variant="contained"
      style={{ backgroundColor: '#4E3C4C', color: 'white' }}
    >
      <SvgIcon viewBox="0 0 256 256" style={{ marginRight: 5 }}>
        <path
          d="M165.964 15.838c-3.89-11.975-16.752-18.528-28.725-14.636-11.975 3.89-18.528 16.752-14.636 28.725l58.947 181.365c4.048 11.187 16.132 17.473 27.732 14.135 12.1-3.483 19.475-16.334 15.614-28.217L165.964 15.838"
          fill="#FFFFFF"
        />
        <path
          d="M74.626 45.516C70.734 33.542 57.873 26.989 45.9 30.879 33.924 34.77 27.37 47.631 31.263 59.606l58.948 181.366c4.047 11.186 16.132 17.473 27.732 14.132 12.099-3.481 19.474-16.332 15.613-28.217L74.626 45.516"
          fill="#FFFFFF"
        />
        <path
          d="M240.162 166.045c11.975-3.89 18.526-16.75 14.636-28.726-3.89-11.973-16.752-18.527-28.725-14.636L44.708 181.632c-11.187 4.046-17.473 16.13-14.135 27.73 3.483 12.099 16.334 19.475 28.217 15.614l181.372-58.93"
          fill="#FFFFFF"
        />
        <path
          d="M82.508 217.27l43.347-14.084-14.086-43.352-43.35 14.09 14.089 43.347"
          fill="#FFFFFF"
        />
        <path
          d="M173.847 187.591c16.388-5.323 31.62-10.273 43.348-14.084l-14.088-43.36-43.35 14.09 14.09 43.354"
          fill="#FFFFFF"
        />
        <path
          d="M210.484 74.706c11.974-3.89 18.527-16.751 14.637-28.727-3.89-11.973-16.752-18.526-28.727-14.636L15.028 90.293C3.842 94.337-2.445 106.422.896 118.022c3.481 12.098 16.332 19.474 28.217 15.613l181.371-58.93"
          fill="#FFFFFF"
        />
        <path
          d="M52.822 125.933c11.805-3.836 27.025-8.782 43.354-14.086-5.323-16.39-10.273-31.622-14.084-43.352l-43.36 14.092 14.09 43.346"
          fill="#FFFFFF"
        />
        <path
          d="M144.16 96.256l43.356-14.088a546179.21 546179.21 0 0 0-14.089-43.36L130.07 52.9l14.09 43.356"
          fill="#FFFFFF"
        />
      </SvgIcon>
      Add to Slack
    </Button>
  );
};

const {
  NODE_ENV,
  REACT_APP_ENV,
  REACT_APP_SLACK_CLIENT_ID,
  REACT_APP_SLACK_OAUTH_REDIRECT_URL,
} = process.env;

export const getAddToSlackState = () => {
  // XXX when more state parameters are needed, separate with ':' or some other delimeter

  if (NODE_ENV === 'development') {
    // if launched with yarn start...
    return 'localhost';
  } else if (REACT_APP_ENV === 'dvlp') {
    return 'dvlp';
  } else {
    return 'prod';
  }
};

export const AddToSlackButtonWhiteX = () => {
  const state = getAddToSlackState();
  const scope =
    'app_mentions:read,channels:join,channels:read,chat:write,commands,groups:read,im:history,im:write,team:read,usergroups:read,users:read,users:read.email';

  const href =
    `https://slack.com/oauth/v2/authorize?` +
    `client_id=${REACT_APP_SLACK_CLIENT_ID}&` +
    `redirect_uri=${REACT_APP_SLACK_OAUTH_REDIRECT_URL}&` +
    `scope=${scope}&` +
    `state=${state}`;

  return (
    <Button
      variant="contained"
      size="large"
      color="primary"
      //href={`https://slack.com/oauth/authorize?client_id=${REACT_APP_SLACK_CLIENT_ID}&scope=commands,bot`}
      //href={`https://slack.com/oauth/v2/authorize?client_id=${REACT_APP_SLACK_CLIENT_ID}&scope=bot,channels:read,chat:write:bot,chat:write:user,commands,groups:read,identify,im:read,im:write,mpim:read,reactions:read,reactions:write,team:read,users:read,users:read.email&user_scope=bot,channels:read,chat:write:bot,commands,reactions:read,reactions:write,team:read,users:read,users:read.email`}
      //href={`https://slack.com/oauth/v2/authorize?client_id=${REACT_APP_SLACK_CLIENT_ID}&install_redirect=oauth&scope=channels:read,commands,groups:read,im:read,im:write,mpim:read,reactions:read,reactions:write,team:read,users:read,app_mentions:read,im:history,usergroups:read&user_scope=channels:read,team:read,users:read`}
      //href={`https://slack.com/oauth/v2/authorize?redirect_uri=${REACT_APP_SLACK_OAUTH_REDIRECT_URL}&client_id=${REACT_APP_SLACK_CLIENT_ID}&scope=channels:read,commands,groups:read,im:read,im:write,mpim:read,reactions:read,reactions:write,team:read,users:read,app_mentions:read,im:history,usergroups:read&user_scope=channels:read,team:read,users:read`}
      //href={`https://slack.com/oauth/v2/authorize?client_id=${REACT_APP_SLACK_CLIENT_ID}&scope=app_mentions:read,channels:join,channels:read,chat:write,commands,groups:read,im:history,im:read,im:write,mpim:read,mpim:write,reactions:read,reactions:write,team:read,usergroups:read,usergroups:write,users:read&user_scope=channels:read,identity.basic,team:read,users:read
      href={href}
      style={{
        //padding: '12px',
        //borderRadius: '10px',
        color: 'white',
        //background: 'linear-gradient(90deg,#b87df9,#6a4ee1)',
        //boxShadow: '0 3px 5px 2px rgba(184, 125, 249, 0.3)',
      }}
    >
      <SlackSvgIcon />
      Add to Slack
    </Button>
  );
};

export const AddToSlackButtonWhiteY = () => {
  return (
    <Button
      variant="outlined"
      size="large"
      style={{
        padding: '12px',
        borderRadius: '10px',
        backgroundColor: '#4A154B',
        color: 'white',
      }}
    >
      <SvgIcon
        height="122.8"
        width="122.8"
        viewBox="0 0 122.8 122.8"
        y="0px"
        x="0px"
        id="Layer_1"
        version="1.1"
        style={{ marginRight: '10px' }}
      >
        <g transform="translate(-73.6,-73.6)" id="g26">
          <g id="g6">
            <path
              fill="#e01e5a"
              id="path2"
              d="m 99.4,151.2 c 0,7.1 -5.8,12.9 -12.9,12.9 -7.1,0 -12.9,-5.8 -12.9,-12.9 0,-7.1 5.8,-12.9 12.9,-12.9 h 12.9 z"
            />
            <path
              fill="#e01e5a"
              id="path4"
              d="m 105.9,151.2 c 0,-7.1 5.8,-12.9 12.9,-12.9 7.1,0 12.9,5.8 12.9,12.9 v 32.3 c 0,7.1 -5.8,12.9 -12.9,12.9 -7.1,0 -12.9,-5.8 -12.9,-12.9 z"
            />
          </g>
          <g id="g12">
            <path
              fill="#36c5f0"
              id="path8"
              d="m 118.8,99.4 c -7.1,0 -12.9,-5.8 -12.9,-12.9 0,-7.1 5.8,-12.9 12.9,-12.9 7.1,0 12.9,5.8 12.9,12.9 v 12.9 z"
            />
            <path
              fill="#36c5f0"
              id="path10"
              d="m 118.8,105.9 c 7.1,0 12.9,5.8 12.9,12.9 0,7.1 -5.8,12.9 -12.9,12.9 H 86.5 c -7.1,0 -12.9,-5.8 -12.9,-12.9 0,-7.1 5.8,-12.9 12.9,-12.9 z"
            />
          </g>
          <g id="g18">
            <path
              fill="#2eb67d"
              id="path14"
              d="m 170.6,118.8 c 0,-7.1 5.8,-12.9 12.9,-12.9 7.1,0 12.9,5.8 12.9,12.9 0,7.1 -5.8,12.9 -12.9,12.9 h -12.9 z"
            />
            <path
              fill="#2eb67d"
              id="path16"
              d="m 164.1,118.8 c 0,7.1 -5.8,12.9 -12.9,12.9 -7.1,0 -12.9,-5.8 -12.9,-12.9 V 86.5 c 0,-7.1 5.8,-12.9 12.9,-12.9 7.1,0 12.9,5.8 12.9,12.9 z"
            />
          </g>
          <g id="g24">
            <path
              fill="#ecb22e"
              id="path20"
              d="m 151.2,170.6 c 7.1,0 12.9,5.8 12.9,12.9 0,7.1 -5.8,12.9 -12.9,12.9 -7.1,0 -12.9,-5.8 -12.9,-12.9 v -12.9 z"
            />
            <path
              fill="#ecb22e"
              id="path22"
              d="m 151.2,164.1 c -7.1,0 -12.9,-5.8 -12.9,-12.9 0,-7.1 5.8,-12.9 12.9,-12.9 h 32.3 c 7.1,0 12.9,5.8 12.9,12.9 0,7.1 -5.8,12.9 -12.9,12.9 z"
            />
          </g>
        </g>
      </SvgIcon>
      Add to Slack
    </Button>
  );
};

export const AddToHangoutsButton = () => {
  return (
    <Button variant="contained" color="primary" href="/hangouts">
      <img
        src={hangouts_logo}
        style={{ height: 32 }}
        alt="Google Hangouts Chat"
      />
      Add to Hangouts Chat
    </Button>
  );
};

export const AddToTeamsButton = () => {
  return (
    <Button variant="contained" color="primary">
      <SvgIcon viewBox="0 0 400 400" style={{ marginRight: 5 }}>
        <path
          fill="#FFFFFF"
          d="M52.161,91.376c56.169-9.74,112.316-19.479,169.086-29.324v41.05c12.084-5.004,23.379-4.671,34.077,1.773
		c10.71,6.45,15.827,16.323,16.953,28.771c12.969-19.73,33.659-21.376,47.57-14.27c14.707,7.514,22.119,24.154,17.705,39.817
		c-4.318,15.326-19.91,26.384-35.789,25.211c-15.187-1.119-31.632-11.212-33.59-34.081c-13.709,17.737-23.036,20.838-46.51,15.314
		v11.93c1.805,0.108,3.373,0.283,4.94,0.286c12.116,0.022,24.242,0.319,36.345-0.076c11.306-0.368,17.91,4.773,16.722,14.936
		c-0.04,0.35,0.221,0.732,0.481,1.522c1.559,0.125,3.246,0.369,4.934,0.374c13.057,0.033,26.115-0.02,39.173,0.031
		c10.869,0.044,14.266,3.408,14.27,13.939c0.002,11.445,0.021,22.892-0.006,34.336c-0.051,21.291-15.38,36.536-37.198,36.901
		c-8.064,0.136-16.132,0.023-24.181,0.023c-0.551,0.705-1.023,1.177-1.333,1.733c-7.494,13.472-19.34,19.521-34.756,19.509
		c-6.306-0.003-12.611,0-19.567,0v41.708c-56.932-9.09-113.074-18.055-169.326-27.037V91.376z M221.597,289.869
		c7.313,0,14.163-0.312,20.973,0.066c13.961,0.774,26.166-10.586,26-25.611c-0.262-23.519-0.075-47.044-0.094-70.566
		c-0.002-1.394-0.155-2.787-0.24-4.236h-46.639V289.869z M279.819,268.354c9.348,0,18.274,0.723,27.027-0.177
		c11.917-1.221,20.448-12.181,20.576-25.048c0.113-11.299,0.032-22.599,0.011-33.898c-0.003-1.007-0.215-2.013-0.339-3.098h-47.275
		V268.354z M145.265,167.34c11.002-0.599,21.254-1.157,31.606-1.721v-15.521c-25.949,1.621-51.564,3.221-77.171,4.82v13.717h27.716
		v85.957c6.223,0.297,11.758,0.562,17.849,0.853V167.34z M280.932,149.58c-0.16,12.318,9.636,22.436,21.885,22.597
		c13.315,0.176,23.74-9.442,23.848-22.004c0.105-12.416-9.865-22.452-22.43-22.573C291.062,127.473,281.097,136.871,280.932,149.58
		 M221.531,150.277c8.979,8.527,23.205,8.113,31.945-0.804c8.818-8.995,8.407-23.307-0.916-31.851
		c-8.839-8.1-23.07-8.061-31.029,0.185V150.277z"
        />
      </SvgIcon>
      Add to Teams
    </Button>
  );
};
