import React from 'react';
import Button from '@mui/material/Button';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { Redirect } from 'react-router-dom';
import { signOut } from '../../actions';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  paper: { borderRadius: 10 },
});

const ResponsiveMenuItem = ({ text, icon, onClick }) => (
  <MenuItem
    onClick={onClick}
    style={{
      minHeight: '48px',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      textTransform: 'none',
    }}
  >
    {icon && icon}
    {text}
  </MenuItem>
);

const linkStyle = {
  textDecoration: 'none',
  color: 'black',
};

const LoggedInMenuBar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!props.isSignedIn) {
    return <Redirect to="/" />;
  }
  const classes = useStyles();

  return (
    <div>
      <Button
        aria-owns={open ? 'menu-list-grow' : null}
        aria-haspopup="true"
        aria-label="Menu"
        onClick={handleClick}
        style={{
          textTransform: 'none',
          color: 'rgba(0,0,0,0.87)',
          fontWeight: '500',
        }}
      >
        <Avatar
          alt="Profile Picture"
          src={props.profilePicture}
          style={{ marginRight: '10px', borderRadius: '50%' }}
        />
        Hi {props.firstName}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
      >
        <Paper>
          <MenuList>
            <NavLink to="/dashboard" style={linkStyle}>
              <ResponsiveMenuItem
                onClick={handleClose}
                icon={<DashboardIcon style={{ marginRight: '8px' }} />}
                text="Dashboard"
              />
            </NavLink>
            <NavLink to="/billing" style={linkStyle}>
              <ResponsiveMenuItem
                onClick={handleClose}
                icon={<CreditCardIcon style={{ marginRight: '8px' }} />}
                text="Billing"
              />
            </NavLink>

            <NavLink to="/account" style={linkStyle}>
              <ResponsiveMenuItem
                onClick={handleClose}
                icon={<AccountCircleIcon style={{ marginRight: '8px' }} />}
                text="Account"
              />
            </NavLink>
            <ResponsiveMenuItem
              onClick={() => props.signOut()}
              icon={<PowerSettingsNewIcon style={{ marginRight: '8px' }} />}
              text="Sign out"
            />
          </MenuList>
        </Paper>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  firstName: state.auth.displayName.split(' ')[0],
  profilePicture: state.auth.photoURL,
  isSignedIn: state.auth.isSignedIn,
});

const mapDispatchToProps = {
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInMenuBar);
