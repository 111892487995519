import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';

const styles = {
  label: {},
};

export const HeaderButton = props => (
  <Button {...props} classes={{ label: props.classes.label }} color="inherit">
    {props.text}
  </Button>
);

HeaderButton.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderButton);
