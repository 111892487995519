import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../assets/logo.svg';
import MenuBar from './MenuBar';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  root: {},
  flex: {
    flexGrow: 1,
  },
  leftDiv: {
    marginLeft: -12,
    marginRight: 20,
  },
}));

const Logo = () => (
  <img
    src={logo}
    height="70"
    alt="AnonymityBot Logo"
    style={{
      borderRadius: '50%',
      margin: 10,
    }}
  />
);

const Logotype = () => (
  <div>
    <Typography
      variant="h1"
      color="inherit"
      style={{
        fontWeight: 'bold',
        display: 'inline-block',
        textTransform: 'none',
        color: '#333333',
        fontSize: 20,
      }}
    >
      AnonymityBot
    </Typography>
  </div>
);

const SimpleAppBar = () => {
  const classes = useStyles();

  const toMinWidth = useMediaQuery('(min-width:1024px)');
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.15)',
          backgroundColor: 'rgba(0,0,0,0)',
        }}
        color="inherit"
      >
        <Toolbar
          style={{
            minWidth: toMinWidth ? '1024px' : 'auto',
            margin: toMinWidth ? '0 auto' : '0',
          }}
          disableGutters
        >
          <div style={{ flex: 1 }}>
            <a
              href="https://anonymitybot.com"
              style={{
                textDecoration: 'none',
                color: 'black',
              }}
            >
              <div
                style={{
                  textAlign: 'left',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <Button aria-label="AnonymityBot Home" style={{ padding: 0 }}>
                  <Logo />
                  <Logotype />
                </Button>
              </div>
            </a>
          </div>
          <MenuBar />
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default SimpleAppBar;
