import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import NotFound from './NotFound';
import Success from './Success';
import Failure from './Failure';
import Signin from './Signin';
import Dashboard from './Dashboard/';
import Billing from './Billing';
import Account from './Account';
import { connect } from 'react-redux';

const Main = ({ location }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Switch location={location}>
        <Route exact path="/" component={Signin} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/failure" component={Failure} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/billing" component={Billing} />
        <Route exact path="/account" component={Account} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authInProgress: state.auth.inProgress,
});

const mapDispatchToProps = {};

const ConnectedMain = connect(mapStateToProps, mapDispatchToProps)(Main);

export default withRouter(ConnectedMain);
