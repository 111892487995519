import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';
import { purple } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    //marginLeft: 16,
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: 10,
    textTransform: 'none',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    display: 'inline-block',
    position: 'relative',
  },

  buttonProgress: {
    color: purple[200],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
  },
}));

export default ({ onClick, href, disabled, loading, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onClick}
        href={href}
        disabled={disabled}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};
