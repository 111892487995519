import firebase from '../backend/firebase';
import * as api from '../backend/api';
import * as types from './types';
import jwtDecode from 'jwt-decode';
import {
  SuccessWithUpdateMessageSnackbar,
  ErrorSnackbar,
} from '../actions/notifications';

export const listenToAuthStateChange = () => (dispatch) => {
  //Right after the listener has been registered
  dispatch({ type: types.LISTEN_AUTH_STATE_REQUESTED });
  firebase.auth.onAuthStateChanged(
    async (auth) => {
      if (auth) {
        const accessToken = await auth.getIdToken();
        const { isAdmin, email } = jwtDecode(accessToken);

        const { uid, displayName, photoURL } = auth;
        const [teamId, userId] = uid.split(':');
        const authData = {
          uid,
          teamId,
          userId,
          displayName,
          photoURL,
          isAdmin,
          email,
        };
        dispatch({
          type: types.AUTH_STATE_CHANGED,
          authData,
        });
      } else {
        dispatch({ type: types.NO_AUTH_STATE });
      }
    },
    (error) => console.log(error)
  );
};

export const signInWithCustomToken = (customToken) => async (dispatch) => {
  dispatch({ type: types.SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST });
  try {
    await firebase.auth.signInWithCustomToken(customToken);
    dispatch({ type: types.SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS });
  } catch (error) {
    dispatch({ type: types.SIGN_IN_WITH_CUSTOM_TOKEN_FAILURE, error });
  }
};

export const getUserData = (myIdToken = null) => async (dispatch, getState) => {
  dispatch({ type: types.GET_USER_DATA_REQUEST });
  try {
    let { data, success } = getState().database.user;
    if (!success) {
      const idToken =
        myIdToken || (await firebase.auth.currentUser.getIdToken());
      data = await api.getUserData(idToken);
    }
    dispatch({ type: types.GET_USER_DATA_SUCCESS, data });
  } catch (error) {
    dispatch({ type: types.GET_USER_DATA_FAILURE, error });
  }
};

export const getCommonPrivateChannels = (myIdToken = null) => async (
  dispatch,
  getState
) => {
  dispatch({ type: types.GET_COMMON_PRIVATE_CHANNELS_REQUEST });
  try {
    let { items, success } = getState().database.private_channels;
    if (!success) {
      const idToken =
        myIdToken || (await firebase.auth.currentUser.getIdToken());
      items = await api.getCommonPrivateChannels(idToken);
    }
    dispatch({ type: types.GET_COMMON_PRIVATE_CHANNELS_SUCCESS, items });
  } catch (error) {
    dispatch({ type: types.GET_COMMON_PRIVATE_CHANNELS_FAILURE, error });
  }
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getTeamData = (myIdToken = null) => async (dispatch, getState) => {
  dispatch({ type: types.GET_TEAM_DATA_REQUEST });
  try {
    let { data, success } = getState().database.team;
    if (!success) {
      const idToken =
        myIdToken || (await firebase.auth.currentUser.getIdToken());
      data = await api.getTeamData(idToken);
    }
    dispatch({ type: types.GET_TEAM_DATA_SUCCESS, data });
  } catch (error) {
    dispatch({ type: types.GET_TEAM_DATA_FAILURE, error });
  }
};

export const listenToTeamData = ({ teamId = undefined } = {}) => (
  dispatch,
  getState
) => {
  dispatch({ type: types.LISTEN_TO_TEAM_DATA_REQUEST });

  if (!teamId) {
    teamId = getState().auth.teamId;
  }
  const docPath = `slack_teams/${teamId}`;
  const unsubscribe = firebase.firestore.doc(docPath).onSnapshot(
    (doc) => {
      const data = doc.data();
      dispatch({ type: types.LISTEN_TO_TEAM_DATA_SUCCESS, data });
    },
    (error) => {
      dispatch({ type: types.LISTEN_TO_TEAM_DATA_FAILURE, error });
    }
  );
  return unsubscribe;
};

export const listenToWhitelist = () => (dispatch, getState) => {
  dispatch({ type: types.LISTEN_TO_WHITELIST_REQUEST });

  const { teamId } = getState().auth;
  const colPath = `slack_teams/${teamId}/whitelisted_channels`;
  const unsubscribe = firebase.firestore.collection(colPath).onSnapshot(
    (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => items.push(doc.data()));
      dispatch({ type: types.LISTEN_TO_WHITELIST_SUCCESS, items });
    },
    (error) => {
      dispatch({ type: types.LISTEN_TO_WHITELIST_FAILURE, error });
    }
  );
  return unsubscribe;
};

export const listenToWhisperChannels = () => (dispatch, getState) => {
  dispatch({ type: types.LISTEN_TO_WHISPER_CHANNELS_REQUEST });

  const { teamId } = getState().auth;
  const colPath = `slack_teams/${teamId}/whisper_channels`;
  const unsubscribe = firebase.firestore.collection(colPath).onSnapshot(
    (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => items.push(doc.data()));
      dispatch({ type: types.LISTEN_TO_WHISPER_CHANNELS_SUCCESS, items });
    },
    (error) => {
      dispatch({ type: types.LISTEN_TO_WHISPER_CHANNELS_FAILURE, error });
    }
  );
  return unsubscribe;
};

export const listenToMonthlyUsage = () => (dispatch, getState) => {
  dispatch({ type: types.LISTEN_TO_MONTHLY_USAGE_REQUEST });

  const { teamId } = getState().auth;
  const colPath = `slack_teams/${teamId}/monthly_usage`;
  const unsubscribe = firebase.firestore.collection(colPath).onSnapshot(
    (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) =>
        items.push({ ...doc.data(), year_month: doc.id })
      );
      dispatch({ type: types.LISTEN_TO_MONTHLY_USAGE_SUCCESS, items });
    },
    (error) => {
      dispatch({ type: types.LISTEN_TO_MONTHLY_USAGE_FAILURE, error });
    }
  );
  return unsubscribe;
};

export const getChannels = (startsWith = '') => async (dispatch, getState) => {
  dispatch({ type: types.GET_CHANNELS_REQUEST });
  try {
    const { teamId } = getState().auth;
    const channelsPath = `/slack_teams/${teamId}/channels`;
    let query = firebase.firestore.collection(channelsPath);
    if (startsWith) {
      const upperBound = startsWith.replace(/.$/, (c) =>
        String.fromCharCode(c.charCodeAt(0) + 1)
      );
      console.log('upperBound', upperBound);
      query = query
        .where('name', '>=', startsWith)
        .where('name', '<', upperBound);
    }
    const snapshot = await query.orderBy('name').limit(50).get();
    const data = await snapshot.docs.map((doc) => doc.data());
    dispatch({ type: types.GET_CHANNELS_SUCCESS, data });
  } catch (error) {
    dispatch({ type: types.GET_CHANNELS_FAILURE, error });
  }
};

export const toggleWhitelist = (toggle, myIdToken = null) => async (
  dispatch
) => {
  const data = { toggle };
  dispatch({ type: types.TOGGLE_WHITELIST_REQUEST, data });
  try {
    const idToken = myIdToken || (await firebase.auth.currentUser.getIdToken());
    await api.toggleWhitelist(idToken, data);
    dispatch({ type: types.TOGGLE_WHITELIST_SUCCESS, data });
    dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
  } catch (error) {
    dispatch({ type: types.TOGGLE_WHITELIST_FAILURE, error });
  }
};

export const addToWhitelist = (channel, myIdToken = null) => async (
  dispatch
) => {
  const data = {
    channel: {
      id: channel.id,
      name: channel.name,
      is_private: channel.is_private,
    },
  };
  dispatch({ type: types.ADD_TO_WHITELIST_REQUEST, data });
  try {
    const idToken = myIdToken || (await firebase.auth.currentUser.getIdToken());
    await api.addToWhitelist(idToken, data);
    dispatch({ type: types.ADD_TO_WHITELIST_SUCCESS, data });
    dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
  } catch (error) {
    dispatch({ type: types.ADD_TO_WHITELIST_FAILURE, error });
    dispatch(enqueueSnackbar(ErrorSnackbar(dispatch)));
  }
};

export const removeFromWhitelist = (channel) => async (dispatch) => {
  const data = { channel: { id: channel.id } };
  dispatch({ type: types.REMOVE_FROM_WHITELIST_REQUEST, channel });
  try {
    const idToken = await firebase.auth.currentUser.getIdToken();
    await api.removeFromWhitelist(idToken, data);
    dispatch({ type: types.REMOVE_FROM_WHITELIST_SUCCESS, channel });
    dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
  } catch (error) {
    dispatch({ type: types.REMOVE_FROM_WHITELIST_FAILURE, error });
    dispatch(enqueueSnackbar(ErrorSnackbar(dispatch)));
  }
};

export const toggleWhispers = (toggle, myIdToken = null) => async (
  dispatch
) => {
  const data = { toggle };
  dispatch({ type: types.TOGGLE_WHISPER_CHANNELS_REQUEST, data });
  try {
    const idToken = myIdToken || (await firebase.auth.currentUser.getIdToken());
    await api.toggleWhisperChannels(idToken, data);
    dispatch({ type: types.TOGGLE_WHISPER_CHANNELS_SUCCESS, data });
    dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
  } catch (error) {
    dispatch({ type: types.TOGGLE_WHISPER_CHANNELS_FAILURE, error });
  }
};

export const addToWhisperChannels = (channel, myIdToken = null) => async (
  dispatch
) => {
  const data = {
    channel: {
      id: channel.id,
      name: channel.name,
      is_private: channel.is_private,
    },
  };
  dispatch({ type: types.ADD_TO_WHISPER_CHANNELS_REQUEST, data });
  try {
    const idToken = myIdToken || (await firebase.auth.currentUser.getIdToken());
    await api.addToWhisperChannels(idToken, data);
    dispatch({ type: types.ADD_TO_WHISPER_CHANNELS_SUCCESS, data });
    dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
  } catch (error) {
    dispatch({ type: types.ADD_TO_WHISPER_CHANNELS_FAILURE, error });
    dispatch(enqueueSnackbar(ErrorSnackbar(dispatch)));
  }
};

export const removeFromWhisperChannels = (channel) => async (dispatch) => {
  const data = { channel: { id: channel.id } };
  dispatch({ type: types.REMOVE_FROM_WHISPER_CHANNELS_REQUEST, channel });
  try {
    const idToken = await firebase.auth.currentUser.getIdToken();
    await api.removeFromWhisperChannels(idToken, data);
    dispatch({ type: types.REMOVE_FROM_WHISPER_CHANNELS_SUCCESS, channel });
    dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
  } catch (error) {
    dispatch({ type: types.REMOVE_FROM_WHISPER_CHANNELS_FAILURE, error });
    dispatch(enqueueSnackbar(ErrorSnackbar(dispatch)));
  }
};

export const triggerPubSubPostRegister = () => async (dispatch) => {
  const data = {};
  dispatch({ type: types.TRIGGER_PUBSUB_POST_REGISTER_REQUEST });
  try {
    const idToken = await firebase.auth.currentUser.getIdToken();
    await api.triggerPubSubPostRegister(idToken, data);
    dispatch({ type: types.TRIGGER_PUBSUB_POST_REGISTER_SUCCESS });
    dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
  } catch (error) {
    dispatch({ type: types.TRIGGER_PUBSUB_POST_REGISTER_FAILURE, error });
    dispatch(enqueueSnackbar(ErrorSnackbar(dispatch)));
  }
};

export const triggerPubSubRefreshUsers = ({
  showSnackbar = true,
} = {}) => async (dispatch) => {
  const data = {};
  dispatch({ type: types.TRIGGER_PUBSUB_REFRESH_USERS_REQUEST });
  try {
    const idToken = await firebase.auth.currentUser.getIdToken();
    await api.triggerPubSubRefreshUsers(idToken, data);
    dispatch({ type: types.TRIGGER_PUBSUB_REFRESH_USERS_SUCCESS });
    if (showSnackbar) {
      dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
    }
  } catch (error) {
    dispatch({ type: types.TRIGGER_PUBSUB_REFRESH_USERS_FAILURE, error });
    if (showSnackbar) {
      dispatch(enqueueSnackbar(ErrorSnackbar(dispatch)));
    }
  }
};

export const getEnterprisePaymentLink = () => async (dispatch) => {
  const data = {};
  dispatch({ type: types.GET_ENTERPRISE_PAYMENT_LINK_REQUEST });
  try {
    const idToken = await firebase.auth.currentUser.getIdToken();
    const { overrideUrl } = await api.getEnterprisePaymentLink(idToken, data);
    dispatch({ type: types.GET_ENTERPRISE_PAYMENT_LINK_SUCCESS });
    return overrideUrl;
  } catch (error) {
    dispatch({ type: types.GET_ENTERPRISE_PAYMENT_LINK_FAILURE, error });
  }
};

export const getTransactions = () => async (dispatch) => {
  const data = {};
  dispatch({ type: types.GET_TRANSACTIONS_REQUEST });
  try {
    const idToken = await firebase.auth.currentUser.getIdToken();
    const { response } = await api.getTransactions(idToken, data);
    dispatch({ type: types.GET_TRANSACTIONS_SUCCESS, data: response });
  } catch (error) {
    dispatch({ type: types.GET_TRANSACTIONS_FAILURE, error });
  }
};

export const getSubscriptionDetails = () => async (dispatch) => {
  const data = {};
  dispatch({ type: types.GET_SUBSCRIPTION_DETAILS_REQUEST });
  try {
    const idToken = await firebase.auth.currentUser.getIdToken();
    const { response } = await api.getSubscriptionDetails(idToken, data);
    dispatch({ type: types.GET_SUBSCRIPTION_DETAILS_SUCCESS, data: response });
  } catch (error) {
    dispatch({ type: types.GET_SUBSCRIPTION_DETAILS_FAILURE, error });
  }
};

export const updateSettings = (settings) => async (dispatch) => {
  const data = { settings };
  dispatch({ type: types.UPDATE_SETTINGS_REQUEST, settings });
  try {
    const idToken = await firebase.auth.currentUser.getIdToken();
    await api.updateSettings(idToken, data);
    dispatch({ type: types.UPDATE_SETTINGS_SUCCESS, settings });
    dispatch(enqueueSnackbar(SuccessWithUpdateMessageSnackbar(dispatch)));
  } catch (error) {
    dispatch({ type: types.UPDATE_SETTINGS_FAILURE, error });
    dispatch(enqueueSnackbar(ErrorSnackbar(dispatch)));
  }
};

export const setAuthStateTeamId = (teamId) => async (dispatch) =>
  dispatch({ type: types.SET_AUTH_STATE_TEAM_ID, teamId });

export const setAuthStateIsAdmin = (isAdmin) => async (dispatch) =>
  dispatch({ type: types.SET_AUTH_STATE_IS_ADMIN, isAdmin });

export const clearRedux = () => ({ type: types.CLEAR_REDUX });

export const signOut = () => async (dispatch) => {
  dispatch({ type: types.SIGN_OUT_REQUEST });

  await firebase.auth
    .signOut()
    .catch((error) => dispatch({ type: types.SIGN_OUT_FAILURE, error }));

  dispatch(clearRedux());
  dispatch({ type: types.SIGN_OUT_SUCCESS });
};

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: types.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: types.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: types.REMOVE_SNACKBAR,
  key,
});
