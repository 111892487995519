import React from 'react';
import Lottie from 'react-lottie';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const animationData = require('../assets/error_smiley.json');

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        <div style={{ textAlign: 'center', margin: '10px' }}>
          <Typography variant="h4" gutterBottom>
            Ooops
          </Typography>
          <Typography variant="h5" color="textSecondary" gutterBottom>
            {`We couldn't find the page you were looking for...`}
          </Typography>
          <Button
            aria-label="Back to Homepage"
            variant="contained"
            color="primary"
            size="large"
            style={{ marginTop: '1em' }}
            component={Link}
            to="/"
          >
            Back to Home
          </Button>
        </div>
      </div>
    );
  }
}

export default NotFound;
