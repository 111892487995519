import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CardProgress from '../../Common/CardProgress';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import {
  getTeamData,
  toggleWhitelist,
  addToWhitelist,
  listenToWhitelist,
  removeFromWhitelist,
} from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from './AsyncSelect';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import { CardHeader, CardContent } from '@mui/material';
import InfoTooltip from './InfoTooltip';
import hashtag from '../../assets/hashtag.svg';
import lock from '../../assets/lock.svg';
import lightbulb from '../../assets/lightbulb.svg';
import ContainedButton from '../../Common/ContainedButton';

//import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    borderRadius: 10,
    textTransform: 'none',
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: 16,
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {},
  hashtagAvatar: {
    color: '#000',
    backgroundColor: '#fff',
  },
}));

const AddToWhitelistPanel = ({ disabled, inProgress }) => {
  const [selectedChannel, setSelectedChannel] = React.useState(null);

  const dispatch = useDispatch();
  return (
    <Grid direction="column" container spacing={2} style={{ maxWidth: 300 }}>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 400, display: 'inline' }}
        >
          Select a channel
        </Typography>
        <InfoTooltip
          gutterBottom
          title="Type the name of the channel you want to whitelist, select it from the list and click on the action button below."
        />
      </Grid>
      <Grid item>
        <AsyncSelect
          disabled={disabled}
          setSelectedChannel={setSelectedChannel}
        />
      </Grid>
      <Grid item>
        <ContainedButton
          loading={inProgress}
          disabled={disabled}
          onClick={() => {
            if (selectedChannel) {
              // XXX what if selectedChannel is null?
              dispatch(addToWhitelist(selectedChannel));
            } else {
              // dispatch() a notif about selecting a channel.
            }
          }}
        >
          Add to Whitelist
        </ContainedButton>
      </Grid>
    </Grid>
  );
};

const WhitelistListItem = ({ disabled, classes, name, id, isPrivate }) => {
  const dispatch = useDispatch();
  const onDeleteClick = () => {
    const channel = { id, name };
    dispatch(removeFromWhitelist(channel));
  };
  return (
    <ListItem disabled={disabled} disableGutters>
      <Avatar className={classes.hashtagAvatar}>
        <img src={isPrivate ? lock : hashtag} height="20" />
      </Avatar>
      <ListItemText
        primary={name}
        primaryTypographyProps={{ variant: 'subtitle2' }}
        style={{ marginRight: '20px' }}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={onDeleteClick}
          disabled={disabled}
          edge="end"
          aria-label="delete"
          size="large">
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
const WhitelistedChannelsListPanel = ({ disabled, classes }) => {
  const whitelistedChannels = useSelector(
    (state) => state.database.whitelist.items
  );
  return (
    <Grid direction="column" container spacing={2} style={{ maxWidth: 300 }}>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 400, display: 'inline' }}
        >
          Whitelisted channels
        </Typography>
        <InfoTooltip
          gutterBottom
          title="Anyone can send anonymous messages to public channels. Only members of a private channels can send an anonymous messages to it."
        />
      </Grid>
      <Grid item>
        <List dense={false} disablePadding>
          {!whitelistedChannels.length && (
            <Typography variant="body2"> Empty whitelist...</Typography>
          )}
          {whitelistedChannels.map((channel, i) => (
            <WhitelistListItem
              key={i}
              disabled={disabled}
              classes={classes}
              name={channel.name}
              id={channel.id}
              isPrivate={channel.is_private || null}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

const WhitelistPanel = ({ teamData, toggleWhitelist, inProgress }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  React.useEffect(() => {
    const unsubscribe = dispatch(listenToWhitelist());
    return unsubscribe;
  }, []);
  const classes = useStyles();

  const isWhitelistEnabled = !!teamData['whitelist_enabled'];
  const [state, setState] = React.useState({
    checkedA: isWhitelistEnabled,
  });

  const handleChange = (name) => async ({ target: { checked } }) => {
    try {
      await toggleWhitelist(checked);
      setState({ ...state, [name]: checked });
    } catch (err) {
      console.log(err);
    }
  };

  const disabledSwitch = inProgress || !isAdmin;
  const disabledRestOfPanel = disabledSwitch || !state.checkedA;
  return (
    <CardProgress inProgress={inProgress}>
      <CardHeader
        style={{ padding: '16px 24px' }}
        title={
          <div
            style={{
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{
                display: 'inline-flex',
                justifyContent: 'start',
              }}
              gutterBottom
            >
              Channel Whitelist
            </Typography>
            <Switch
              style={{ justifyContent: 'end' }}
              checked={state.checkedA}
              onChange={handleChange('checkedA')}
              color="primary"
              disabled={disabledSwitch}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />

            {/*!isAdmin && <Chip variant="outlined" label="Not Admin" />*/}
          </div>
        }
        subheader="Allow usage of AnonymityBot for a select set of public or private channels"
        subheaderTypographyProps={{
          style: { fontSize: '14px', fontWeight: 400, color: '#546e7a' },
        }}
      />
      <Divider />

      <CardContent style={{ padding: '16px 24px' }}>
        <Grid
          container
          spacing={isMobile ? 0 : 4}
          direction={isMobile ? 'column' : 'row'}
        >
          <Grid item>
            <AddToWhitelistPanel
              disabled={disabledRestOfPanel}
              inProgress={inProgress}
            />
          </Grid>
          {isMobile && (
            <Grid item style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Divider />
            </Grid>
          )}
          <Grid item>
            <WhitelistedChannelsListPanel
              disabled={disabledRestOfPanel}
              classes={classes}
            />
          </Grid>
        </Grid>
        <Typography style={{ fontSize: 12, marginTop: 20 }}>
          <img
            style={{ verticalAlign: 'middle' }}
            src={lightbulb}
            height="20"
          />
          <span style={{ fontWeight: 'bold' }}>Tip: </span>
          To whitelist a private channel, invite AnonymityBot to it and then
          select it from the list above.
        </Typography>
      </CardContent>
    </CardProgress>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.error || state.database.error,
  inProgress: state.database.features.whitelist.inProgress,
  isSignedIn: state.auth.isSignedIn,
  userData: state.database.user.data,
  teamData: state.database.team.data,
});

const mapDispatchToProps = { getTeamData, toggleWhitelist };

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistPanel);
