import { combineReducers } from 'redux';
import * as types from '../actions/types';

export const authInitialState = {
  uid: '',
  userId: '',
  teamId: '',
  displayName: '',
  email: '',
  photoURL: '',
  inProgress: false,
  customSigninInProgress: false,
  isSignedIn: false,
  isAdmin: false,
  isInitialized: false,
  error: '',
};

export const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case types.SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST:
      return { ...state, customSigninInProgress: true };
    case types.LISTEN_AUTH_STATE_REQUESTED:
      return {
        ...state,
        inProgress: true,
        error: '',
      };
    case types.SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS:
      return { ...state, customSigninInProgress: false };
    case types.AUTH_STATE_CHANGED:
      return {
        ...state,
        uid: action.authData.uid,
        userId: action.authData.userId,
        teamId: action.authData.teamId,
        displayName: action.authData.displayName,
        photoURL: action.authData.photoURL,
        inProgress: false,
        isAdmin: action.authData.isAdmin,
        email: action.authData.email,
        isInitialized: true,
        isSignedIn: true,
        error: '',
      };
    case types.SET_AUTH_STATE_TEAM_ID:
      return {
        ...state,
        teamId: action.teamId,
      };
    case types.SET_AUTH_STATE_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin,
      };

    case types.NO_AUTH_STATE:
    case types.SIGN_OUT_SUCCESS:
      return {
        ...authInitialState,
        isInitialized: true,
      };
    case types.SIGN_OUT_FAILURE:
    case types.SIGN_IN_WITH_CUSTOM_TOKEN_FAILURE:
      return {
        ...authInitialState,
        error: action.error,
      };

    default:
      return state;
  }
};

export const databaseInitialState = {
  user: {
    data: {},
    inProgress: false,
    success: false,
    error: '',
  },
  team: {
    data: {},
    inProgress: false,
    success: false,
    error: '',
  },
  monthly_usage: {
    items: [],
    inProgress: false,
    success: false,
    error: '',
  },
  whitelist: {
    items: [],
    inProgress: false,
    success: false,
    error: '',
  },
  whisperChannels: {
    items: [],
    inProgress: false,
    success: false,
    error: '',
  },
  features: {
    whisperChannels: {
      inProgress: false,
      success: false,
      error: '',
    },
    whitelist: {
      inProgress: false,
      success: false,
      error: '',
    },
    settings: {
      inProgress: false,
      success: false,
      error: '',
    },
  },
  channels: {
    data: [],
    inProgress: false,
    success: false,
    error: '',
  },
  private_channels: {
    items: [],
    inProgress: false,
    success: false,
    error: '',
  },
};

export const databaseReducer = (state = databaseInitialState, action) => {
  switch (action.type) {
    case types.GET_USER_DATA_REQUEST:
      return { ...state, user: { ...state.user, inProgress: true } };
    case types.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          inProgress: false,
          success: true,
          data: action.data,
          error: '',
        },
      };
    case types.GET_USER_DATA_FAILURE:
      return {
        ...state,
        user: { ...state.user, inProgress: false, error: action.error },
      };
    case types.GET_COMMON_PRIVATE_CHANNELS_REQUEST:
      return {
        ...state,
        private_channels: { ...state.private_channels, inProgress: true },
      };
    case types.GET_COMMON_PRIVATE_CHANNELS_SUCCESS:
      return {
        ...state,
        private_channels: {
          ...state.private_channels,
          inProgress: false,
          success: true,
          items: action.items,
          error: '',
        },
      };
    case types.GET_COMMON_PRIVATE_CHANNELS_FAILURE:
      return {
        ...state,
        private_channels: {
          ...state.private_channels,
          inProgress: false,
          error: action.error,
        },
      };

    case types.LISTEN_AUTH_STATE_REQUESTED:
      return { ...state, whitelist: { ...state.whitelist, inProgress: true } };

    case types.LISTEN_TO_WHITELIST_REQUEST:
      return {
        ...state,
        whitelist: {
          ...state.whitelist,
          inProgress: true,
        },
      };

    case types.LISTEN_TO_WHITELIST_SUCCESS:
      return {
        ...state,
        whitelist: {
          ...state.whitelist,
          inProgress: false,
          success: true,
          items: action.items,
          error: '',
        },
      };
    case types.LISTEN_TO_WHITELIST_FAILURE:
      return {
        ...state,
        whitelist: {
          ...state.whitelist,
          inProgress: false,
          error: action.error,
        },
      };

    case types.LISTEN_TO_WHISPER_CHANNELS_REQUEST:
      return {
        ...state,
        whisperChannels: {
          ...state.whisperChannels,
          inProgress: true,
        },
      };

    case types.LISTEN_TO_WHISPER_CHANNELS_SUCCESS:
      return {
        ...state,
        whisperChannels: {
          ...state.whisperChannels,
          inProgress: false,
          success: true,
          items: action.items,
          error: '',
        },
      };
    case types.LISTEN_TO_WHISPER_CHANNELS_FAILURE:
      return {
        ...state,
        whisperChannels: {
          ...state.whisperChannels,
          inProgress: false,
          error: action.error,
        },
      };

    case types.LISTEN_TO_MONTHLY_USAGE_REQUEST:
      return {
        ...state,
        monthly_usage: {
          ...state.monthly_usage,
          inProgress: true,
        },
      };
    case types.LISTEN_TO_MONTHLY_USAGE_SUCCESS:
      return {
        ...state,
        monthly_usage: {
          ...state.monthly_usage,
          inProgress: false,
          success: true,
          items: action.items,
          error: '',
        },
      };

    case types.LISTEN_TO_MONTHLY_USAGE_FAILURE:
      return {
        ...state,
        monthly_usage: {
          ...state.monthly_usage,
          inProgress: false,
          error: action.error,
        },
      };

    case types.LISTEN_TO_TEAM_DATA_REQUEST:
      return { ...state, team: { ...state.team, inProgress: true } };
    case types.LISTEN_TO_TEAM_DATA_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          inProgress: false,
          success: true,
          data: action.data,
          error: '',
        },
      };
    case types.LISTEN_TO_TEAM_DATA_FAILURE:
      return {
        ...state,
        team: { ...state.team, inProgress: false, error: action.error },
      };
    case types.GET_CHANNELS_REQUEST:
      return { ...state, channels: { ...state.channels, inProgress: true } };
    case types.GET_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: {
          ...state.channels,
          inProgress: false,
          success: true,
          data: action.data,
          error: '',
        },
      };
    case types.GET_CHANNELS_FAILURE:
      return {
        ...state,
        channels: { ...state.channels, inProgress: false, error: action.error },
      };
    case types.TOGGLE_WHITELIST_REQUEST:
    case types.ADD_TO_WHITELIST_REQUEST:
    case types.REMOVE_FROM_WHITELIST_REQUEST:
      return {
        ...state,
        features: {
          ...state.features,
          whitelist: {
            ...state.features.whitelist,
            inProgress: true,
            error: '',
          },
        },
      };
    case types.TOGGLE_WHITELIST_SUCCESS:
    case types.ADD_TO_WHITELIST_SUCCESS:
    case types.REMOVE_FROM_WHITELIST_SUCCESS:
      return {
        ...state,
        features: {
          ...state.features,
          whitelist: {
            ...state.features.whitelist,
            inProgress: false,
            success: true,
          },
        },
      };
    case types.TOGGLE_WHITELIST_FAILURE:
    case types.ADD_TO_WHITELIST_FAILURE:
    case types.REMOVE_FROM_WHITELIST_FAILURE:
      return {
        ...state,
        features: {
          ...state.features,
          whitelist: {
            ...state.features.whitelist,
            inProgress: false,
            error: action.error,
            success: false,
          },
        },
      };

    case types.TOGGLE_WHISPER_CHANNELS_REQUEST:
    case types.ADD_TO_WHISPER_CHANNELS_REQUEST:
    case types.REMOVE_FROM_WHISPER_CHANNELS_REQUEST:
      return {
        ...state,
        features: {
          ...state.features,
          whisperChannels: {
            ...state.features.whisperChannels,
            inProgress: true,
            error: '',
          },
        },
      };
    case types.TOGGLE_WHISPER_CHANNELS_SUCCESS:
    case types.ADD_TO_WHISPER_CHANNELS_SUCCESS:
    case types.REMOVE_FROM_WHISPER_CHANNELS_SUCCESS:
      return {
        ...state,
        features: {
          ...state.features,
          whisperChannels: {
            ...state.features.whisperChannels,
            inProgress: false,
            success: true,
          },
        },
      };
    case types.TOGGLE_WHISPER_CHANNELS_FAILURE:
    case types.ADD_TO_WHISPER_CHANNELS_FAILURE:
    case types.REMOVE_FROM_WHISPER_CHANNELS_FAILURE:
      return {
        ...state,
        features: {
          ...state.features,
          whisperChannels: {
            ...state.features.whisperChannels,
            inProgress: false,
            error: action.error,
            success: false,
          },
        },
      };

    case types.UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        features: {
          ...state.features,
          settings: {
            ...state.features.settings,
            inProgress: true,
            error: '',
          },
        },
      };
    case types.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        features: {
          ...state.features,
          settings: {
            ...state.features.settings,
            inProgress: false,
            success: true,
          },
        },
      };
    case types.UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        features: {
          ...state.features,
          settings: {
            ...state.features.settings,
            inProgress: false,
            error: action.error,
            success: false,
          },
        },
      };

    default:
      return state;
  }
};

export const uiInitialState = {
  notifications: [],
};

export const uiReducer = (state = uiInitialState, action) => {
  switch (action.type) {
    case types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };

    case types.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };

    default:
      return state;
  }
};

export const paddleInitialState = {
  transactions: {
    data: [],
    inProgress: false,
    success: false,
    error: '',
  },
  subscription_details: {
    data: {},
    inProgress: false,
    success: false,
    error: '',
  },
};

export const paddleReducer = (state = paddleInitialState, action) => {
  switch (action.type) {
    case types.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        transactions: { ...state.transactions, inProgress: true },
      };
    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          inProgress: false,
          success: true,
          data: action.data,
          error: '',
        },
      };
    case types.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          inProgress: false,
          error: action.error,
        },
      };
    case types.GET_SUBSCRIPTION_DETAILS_REQUEST:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          inProgress: true,
        },
      };
    case types.GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          inProgress: false,
          success: true,
          data: action.data,
          error: '',
        },
      };
    case types.GET_SUBSCRIPTION_DETAILS_FAILURE:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          inProgress: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
const appReducer = combineReducers({
  auth: authReducer,
  database: databaseReducer,
  ui: uiReducer,
  paddle: paddleReducer,
});

// https://stackoverflow.com/a/35641992
const rootReducer = (state, action) => {
  if (action.type === types.CLEAR_REDUX) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
