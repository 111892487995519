import React from 'react';
import Logo from './Logo';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';

const Loading = () => {
  return (
    <Grid
      alignItems="center"
      direction="column"
      container
      style={{ margin: 'auto 0px' }}
    >
      <Grid item>
        <Logo height="100px" borderRadius="50%" padding="0px" />
      </Grid>
      <Grid item>
        <LinearProgress
          style={{ width: '100px', height: '3px', marginTop: '20px' }}
        />
      </Grid>
    </Grid>
  );
};

export default Loading;
