import React from 'react';
import {
  Typography,
  Divider,
  CardHeader,
  CardActions,
  CardContent,
  Paper,
  Collapse,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import CardProgress from '../../Common/CardProgress';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from '../../../actions';
import { Radar } from 'react-chartjs-2';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Alert, AlertTitle } from '@mui/material';
import clsx from 'clsx';
import ContainedButton from '../../Common/ContainedButton';
import MaterialTable from '@material-table/core';
//import InfoTooltip from './InfoTooltip';
import lightbulb from '../../assets/lightbulb.svg';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: 16,
  },
  checkboxLabel: {
    fontSize: '12px',
  },
  action: {
    alignItems: 'end',
  },
}));

const getConfigFromTeamData = (teamData) => {
  const config = {};
  const defaultConfig = {
    only_allow_within_perspective_limits: false,
    only_allow_english_text: false,
    perspective_models: [
      { model: 'IDENTITY_ATTACK', enabled: true, percentage: 50 },
      { model: 'THREAT', enabled: true, percentage: 50 },
      { model: 'TOXICITY', enabled: true, percentage: 50 },
      { model: 'PROFANITY', enabled: true, percentage: 50 },
      { model: 'INSULT', enabled: true, percentage: 50 },
      { model: 'SPAM', enabled: false, percentage: 80 },
      { model: 'INCOHERENT', enabled: false, percentage: 80 },
      { model: 'UNSUBSTANTIAL', enabled: false, percentage: 80 },
    ],
  };

  Object.keys(defaultConfig).forEach((key) => {
    if (key in teamData) {
      if (key === 'perspective_models') {
        config[key] || (config[key] = []);

        for (const defaultModelData of defaultConfig[key]) {
          const modelData = teamData[key].find(
            (modelData) => defaultModelData.model === modelData.model
          );

          if (modelData) {
            config[key].push(modelData);
          } else {
            config[key].push(defaultModelData);
          }
        }
      } else {
        config[key] = teamData[key];
      }
    }
  });

  return { ...defaultConfig, ...config };
};
const percentages = {};

[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].forEach((x) => (percentages[x] = x));

function Editable(props) {
  const { useState } = React;

  const [columns] = useState([
    {
      title: 'Model',
      field: 'model',
      editable: 'never',
      headerStyle: {
        textAlign: 'center',
        padding: 0,
      },

      cellStyle: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '0.875em',
        color: '#6D6DAA',
        padding: 0,
      },
    },

    {
      title: 'Enabled',
      field: 'enabled',
      type: 'boolean',
      headerStyle: {
        textAlign: 'center',
        padding: 0,
      },

      cellStyle: {
        textAlign: 'center',
        fontSize: '0.875em',
        padding: 0,
      },
    },
    {
      title: 'Percentage',
      field: 'percentage',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        padding: 0,
      },
      cellStyle: {
        textAlign: 'center',
        fontSize: '0.875em',
        padding: 0,
      },
      lookup: percentages,
    },
  ]);

  const { perspectiveModels, setModelsState, isDisabled } = props;

  return (
    <MaterialTable
      //key={Math.random()}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      title="AI Models"
      columns={columns}
      data={perspectiveModels.map((x, id) => ({ ...x, id }))}
      icons={{
        Edit: () => <EditIcon />,
        Check: () => <CheckIcon />,
        Clear: () => <ClearIcon />,
        Delete: () => <DeleteOutlineIcon />,
        ThirdStateCheck: () => <RemoveIcon />,
        //ThirdStateCheck: () => <CheckBoxOutlineBlankIcon />,
        SortArrow: () => <ArrowDownwardIcon />,
      }}
      options={{
        actionsColumnIndex: -1,
        padding: 'dense',
        paging: false,
        search: false,
        sorting: false,
        removable: false,
        draggable: false,
        tableLayout: 'auto',
      }}
      editable={{
        onRowUpdate: isDisabled
          ? undefined
          : (newData) =>
              new Promise((resolve) => {
                const dataUpdate = [...perspectiveModels];

                for (let i = 0; i < dataUpdate.length; i++) {
                  if (dataUpdate[i].model === newData.model) {
                    dataUpdate[i].enabled = newData.enabled;
                    dataUpdate[i].percentage = parseInt(newData.percentage);
                  }
                }
                setModelsState(dataUpdate);
                resolve();
              }),
      }}
    />
  );
}

const MODEL_TO_NAME = {
  TOXICITY: 'Toxicity',
  PROFANITY: 'Profanity',
  INSULT: 'Insult',
  IDENTITY_ATTACK: 'Identity\nAttack',
  THREAT: 'Threat',
  SPAM: 'Spam',
  UNSUBSTANTIAL: 'Unsubstantial',
  INCOHERENT: 'Incoherent',
  LIKELY_TO_REJECT: 'Likely to reject',
};

const PerspectivePanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const teamData = useSelector((state) => state.database.team.data);
  const inProgress = useSelector(
    (state) => state.database.features.settings.inProgress
  );
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const storedTeamConfig = getConfigFromTeamData(teamData);
  const [state, setState] = React.useState(storedTeamConfig);
  const [expanded, setExpanded] = React.useState(false);
  const setModelsState = (perspectiveModels) =>
    setState({ ...state, perspective_models: perspectiveModels });

  const { perspective_models /*disable_perspective_enforcement*/ } = state;

  const isSwitchDisabled = !isAdmin || inProgress;
  const isRestOfPanelDisabled = isSwitchDisabled;

  const getSettingsFromState = () => {
    const settings = JSON.parse(JSON.stringify(state)); // deep copy
    // Remove table related data
    if (settings['perspective_models']) {
      for (let i = 0; i < settings.perspective_models.length; i++) {
        settings.perspective_models[i].tableData = undefined;
      }
    }
    return settings;
  };
  const handleClick = () => {
    const settings = getSettingsFromState();
    dispatch(updateSettings(settings));
  };
  /*
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  */
  const labels = perspective_models
    .filter(({ enabled }) => enabled)
    .map(({ model }) => MODEL_TO_NAME[model]);
  const labelsData = perspective_models
    .filter(({ enabled }) => enabled)
    .map(({ percentage }) => percentage);

  const cache = new Map();
  let width = null;
  let height = null;
  function createRadialGradient3(context, c1, c2, c3) {
    const chartArea = context.chart.chartArea;
    if (!chartArea) {
      // This case happens on initial chart load
      return null;
    }

    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (width !== chartWidth || height !== chartHeight) {
      cache.clear();
    }
    var gradient = cache.get(c1 + c2 + c3);
    if (!gradient) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      //const centerX = (chartArea.left + chartArea.right) / 2;
      //const centerY = (chartArea.top + chartArea.bottom) / 2;
      const centerX = context.chart.scales.r.xCenter;
      const centerY = context.chart.scales.r.yCenter;
      const r = Math.min(
        (chartArea.right - chartArea.left) / 2,
        (chartArea.bottom - chartArea.top) / 2
      );
      var ctx = context.chart.ctx;
      gradient = ctx.createRadialGradient(
        centerX,
        centerY,
        0,
        centerX,
        centerY,
        r
      );
      gradient.addColorStop(0.2, c1);
      gradient.addColorStop(0.4, c2);
      gradient.addColorStop(0.75, c3);
      cache.set(c1 + c2 + c3, gradient);
    }

    return gradient;
  }
  /*
  const getGradient = (context) => {
    const { ctx } = context.chart;
    const gradient = ctx.createLinearGradient(0, 150, 300, 150);

    gradient.addColorStop(0, 'rgba(234, 25, 78, 1.000)');
    gradient.addColorStop(0.5, 'rgba(0, 8, 249, 1.000)');
    gradient.addColorStop(1, 'rgba(42, 255, 0, 1.000)');
    return gradient;
  };
  */
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Workspace Limits',
        backgroundColor: 'rgba(73, 196, 161,0.05)',
        //backgroundColor: 'red',
        borderColor: 'rgb(73, 196, 161)',
        pointBackgroundColor: 'rgb(73, 196, 161)',
        //pointBorderColor: "#33333390",
        pointRadius: 5,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: labelsData,
        z: 2,
      },

      {
        label: 'removeme',
        pointBorderColor: '#00000000',
        pointBackgroundColor: '#00000000',
        borderColor: '#E44D42c0',
        backgroundColor: (context) =>
          createRadialGradient3(context, '#38CA74', '#FEC945', '#E44D42'),
        //'linear-gradient(to right, #E44D42 , #FEC945, #38CA74)',
        data: labels.map(() => 100),
      },
    ],
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const options = {
    maintainAspectRatio: true,
    elements: { line: { tension: 0, borderWidth: 3 } },

    plugins: {
      title: {
        display: false,
        text: 'Perspective Map',
        position: 'top',
        font: {
          size: 20,
          family: 'Lato',
          weight: 'bold',
        },
        padding: 10,
      },
      legend: {
        display: true,
        labels: {
          filter: function (item) {
            // Logic to remove a particular legend item goes here
            return !item.text.includes('removeme');
          },
          font: {
            size: 14,
            family: 'Lato',
            weight: 'bold',
          },
        },
      },
    },

    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 100,

        angleLines: {
          display: true,
          color: 'grey',
        },
        grid: {
          circular: false,
          color: '#808080',
          lineWidth: 0.5,
          z: 1,
        },
        gridLines: {
          display: true,
          color: 'black',
          z: 1,
        },
        ticks: {
          stepSize: 20,
          showLabelBackdrop: true,
          backdropColor: 'rgba(255, 246, 143, 0)',
          color: '#696969',
          tickWidth: 4,
          borderWidth: 4,
          font: {
            weight: 'bold',
            family: 'Lato',
            size: 15,
          },
          z: 1,
        },
        pointLabels: {
          font: {
            size: isMobile ? 12 : 16,
            family: 'Lato',
            weight: 'bold',
          },
          color: '#696969',
        },
      },
    },
  };
  return (
    <CardProgress inProgress={inProgress}>
      <CardHeader
        style={{ padding: '16px 24px' }}
        title={
          <div>
            <Typography
              variant="h5"
              style={{
                display: 'inline-flex',
                justifyContent: 'start',
              }}
              gutterBottom
            >
              Perspective Limits
            </Typography>
            {/*!isAdmin && <Chip variant="outlined" label="Not Admin" />*/}
          </div>
        }
        subheader="Attempt to moderate anonymous messages automatically with AI"
        subheaderTypographyProps={{
          style: { fontSize: '14px', fontWeight: 400, color: '#546e7a' },
        }}
      />
      <Divider />
      <CardContent style={{ padding: '16px 24px' }}>
        {/*
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={
                <Checkbox
                  color="primary"
                  checked={disable_perspective_enforcement}
                  onChange={handleChange('disable_perspective_enforcement')}
                  value="disable_perspective_enforcement"
                  disabled={isRestOfPanelDisabled}
                />
              }
              label={
                <>
                  <Typography style={{ fontSize: '14px', display: 'inline' }}>
                    Disable perspective enfrocement.
                  </Typography>
                  <InfoTooltip title="Perspective analysis will still take place. However, all messages will be permitted (regardless of their perspective score). Please make sure to hit Save Changes." />
                </>
              }
            />
          </FormGroup>
        </FormControl>
        <Divider />
        */}

        <div style={{ maxWidth: 400, margin: '0 auto' }}>
          <Radar data={data} options={options} />
        </div>
        <Alert
          severity="info"
          style={{ marginTop: '20px' }}
          classes={{
            action: classes.action,
          }}
          action={
            <IconButton
              style={{ alignItems: 'end' }}
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        >
          <AlertTitle>What does it mean?</AlertTitle>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography>
                Anonymity might be used irresponsiblity. To help mitigate that
                we introduce perspective detection of anonymous messages.
              </Typography>
              <br />
              <Typography>
                The perceived impact of each message is normalized such that,
                for instance, a toxicity score of <strong>50%</strong> can be
                interpreted as a belief that <strong>50%</strong> of people
                would consider the comment to be toxic.
              </Typography>
            </CardContent>
          </Collapse>
        </Alert>
        <Editable
          perspectiveModels={perspective_models}
          setModelsState={setModelsState}
          isDisabled={isRestOfPanelDisabled}
        />

        {/*
         */}
      </CardContent>
      <Typography
        style={{
          fontSize: 12,
          marginTop: 20,
          paddingLeft: 24,
          paddingBottom: 4,
        }}
      >
        <img style={{ verticalAlign: 'middle' }} src={lightbulb} height="20" />
        <span style={{ fontWeight: 'bold' }}>Tip: </span>
        the SPAM, UNSUBSTANTIAL and INCOHERENT models are very sensitive and
        therefore the default is 80%
      </Typography>

      <Divider />
      <CardActions style={{ paddingLeft: 24, paddingRight: 24 }}>
        <ContainedButton
          color="primary"
          className={classes.button}
          onClick={handleClick}
          disabled={isRestOfPanelDisabled}
        >
          Save Changes
        </ContainedButton>
      </CardActions>
    </CardProgress>
  );
};

export default PerspectivePanel;
