import React from 'react';
import { connect } from 'react-redux';
import LoadingPage from '../Common/LoadingPage';
import { Redirect } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import { getUserData, getCommonPrivateChannels } from '../../actions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}));

const Account = props => {
  const { isSignedIn, getUserData } = props;
  const classes = useStyles();

  React.useEffect(() => {
    isSignedIn && getUserData();
    // isSignedIn && getCommonPrivateChannels();
  }, [isSignedIn]);

  if (props.inProgress) {
    return <LoadingPage />;
  }

  if (!props.isSignedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ textAlign: 'center', margin: '10px' }}>
      <Typography variant="h4" gutterBottom>
        Account
      </Typography>
      <Avatar
        alt="Profile Picture"
        src={props.userData.profilePicture}
        style={{
          width: '7rem',
          height: '7rem',
          margin: '0 auto',
          marginBottom: 40,
          marginTop: 20,
        }}
      />
      <div style={{ width: '250px', margin: '0 auto' }}>
        <TextField
          label="Name"
          value={props.userData.realName || ''}
          className={classes.textField}
          margin="normal"
          fullWidth
          disabled
        />
        <TextField
          label="Email"
          value={props.userData.email || ''}
          className={classes.textField}
          margin="normal"
          fullWidth
          disabled
        />
        <TextField
          label="Team"
          value={props.userData.teamName || ''}
          className={classes.textField}
          margin="normal"
          fullWidth
          disabled
        />

        <TextField
          label="Admin"
          value={props.userData.isAdmin || ''}
          className={classes.textField}
          margin="normal"
          fullWidth
          disabled
        />
      </div>
      <div
        style={{
          width: '100%',
          maxWidth: '350px',
          margin: '0 auto',
          marginTop: '20px',
        }}
      >
        <Typography variant="h6" gutterBottom>
          How to edit your account details?
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          On each Sign-in account details are taken directly from Slack. Update
          your profile on Slack and re-Sign-in.
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  error: state.auth.error || state.database.error,
  inProgress:
    !state.auth.isInitialized ||
    state.auth.inProgress ||
    state.database.user.inProgress,
  isSignedIn: state.auth.isSignedIn,
  userData: state.database.user.data,
});

const mapDispatchToProps = { getUserData, getCommonPrivateChannels };

export default connect(mapStateToProps, mapDispatchToProps)(Account);
