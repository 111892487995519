import React from 'react';

import { closeSnackbar } from './';
import { Alert, AlertTitle } from '@mui/material';

const getAlert = (severity, dispatch) => (key, message) => {
  const alertTitle =
    severity.toLowerCase().charAt(0).toUpperCase() +
    severity.toLowerCase().substring(1);

  return (
    <Alert
      key={key}
      severity={severity}
      onClose={() => dispatch(closeSnackbar(key))}
    >
      <AlertTitle>{alertTitle}</AlertTitle>

      {message}
    </Alert>
  );
};

export const getSnackbar = ({ dispatch, message, severity }) => {
  const key = new Date().getTime() + Math.random();

  return {
    message,
    options: {
      key,
      content: getAlert(severity, dispatch),
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      autoHideDuration: 5000,
    },
  };
};

export const SuccessWithUpdateMessageSnackbar = (dispatch) =>
  getSnackbar({
    dispatch,
    message: 'It may take up to 5 seconds to update our servers.',
    severity: 'success',
  });

export const ErrorSnackbar = (dispatch) =>
  getSnackbar({
    dispatch,
    message: `Something went wrong. Please try again or contact us.`,
    severity: 'error',
  });
