import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingPage from '../../Common/LoadingPage';
import * as actions from '../../../actions';
import InfoTooltip from './InfoTooltip';
import CardProgress from '../../Common/CardProgress';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid, Button, Avatar } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Alert, AlertTitle, useMediaQuery } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import WhitelistPanel from './WhitelistPanel';
import WhisperPanel from './WhisperPanel';
//import PrivateSuggestionBoxPanel from './PrivateSuggestionBoxPanel';
import PerspectivePanel from './PerspectivePanel';
import GeneralSettingsPanel from './GeneralSettingsPanel';
import MonthlyUsagePanel from './MonthlyUsagePanel';
import GodPanel from './GodPanel';
import UpgradePlanButton from '../UpgradePlanButton';
import { useTheme } from '@mui/material/styles';

export const TeamMetric = ({
  MetricIcon,
  backgroundColor,
  label,
  labelData,
  tooltipText,
}) => {
  return (
    <Grid spacing={2} direction="row" container>
      <Grid item>
        <MetricIcon
          style={{
            size: 40,
            color: 'white',
            borderRadius: 28,
            backgroundColor,
            padding: 8,
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          style={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, 0.87)',
            width: 80,
            display: 'inline',
          }}
        >
          {label}
        </Typography>
        <InfoTooltip title={tooltipText} />
        <Typography
          style={{ fontSize: '14px', fontWeight: 300, color: '#333333' }}
        >
          {' '}
          {labelData}
        </Typography>
      </Grid>
    </Grid>
  );
};

const isToday = (firestoreDate) => {
  if (!firestoreDate) {
    return false;
  }
  const someDate = firestoreDate.toDate();
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

export const TeamInfo = () => {
  const dispatch = useDispatch();
  const [openUpgrade, setOpenUpgrade] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const teamData = useSelector((state) => state.database.team.data);
  const {
    nr_users: nrUsers,
    nr_single_channel_guests: nrSingleChannelGuests = 0,
    team_name: teamName,
    team_domain: teamDomain,
    team_icon_url: teamIconUrl,
    last_post_register_time: lastPostRegisterTime,
    last_refresh_users_time: lastRefreshUsersTime,
    is_currently_paying: isCurrentlyPaying = false,
  } = teamData;

  React.useEffect(() => {
    if (!isToday(lastPostRegisterTime) && !isToday(lastRefreshUsersTime)) {
      dispatch(actions.triggerPubSubRefreshUsers({ showSnackbar: false }));
    }
  }, []);

  React.useEffect(() => {
    if (window.location.search === '?upgrade' && !isCurrentlyPaying) {
      setOpenUpgrade(true);
      window.history.replaceState(null, null, 'dashboard');
    }
  }, []);

  let { is_educational, is_probono = false, plan = 'Hobby' } = teamData;

  let planTooltipText = 'Free for now.';

  if (plan.includes('Business') || plan.includes('Enterprise')) {
    planTooltipText = `All plans enable all AnonymityBot's features. Plans differ only by the number of users.`;
  }

  if (is_educational) {
    plan = 'Educational';
    planTooltipText = 'We automatically detect educational email addresses';
  }

  if (is_probono) {
    plan = 'Pro Bono';
    planTooltipText =
      'Someone from your team reached out to us and asked to be included in the Pro Bono tier.';
  }

  return (
    <CardProgress>
      <CardContent
        style={{
          padding: '16px 24px',
        }}
      >
        <Grid direction="column" spacing={3} container>
          <Grid item>
            <Avatar
              variant="rounded"
              alt="team"
              src={teamIconUrl}
              style={{
                width: 100,
                height: 100,
                margin: '0 auto',
                borderRadius: '10%',
                boxShadow:
                  'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              }}
            />
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <Typography variant="h5"> {teamName}</Typography>
            <Button
              size="small"
              href={`https://${teamDomain}.slack.com`}
              target="_blank"
            >
              Launch Slack
              <LaunchIcon style={{ marginLeft: 8 }} fontSize="small" />
            </Button>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            justifyContent="space-between"
            direction="column"
          >
            <Grid item>
              <TeamMetric
                MetricIcon={PeopleAltIcon}
                backgroundColor="#68c3a3"
                label="Users"
                labelData={nrUsers - nrSingleChannelGuests}
                tooltipText="The number of users, including multi-channel guests, not including single-channel guests and bots."
              />
            </Grid>
            <Grid item>
              <TeamMetric
                MetricIcon={LocalOfferIcon}
                backgroundColor="#68c3a3"
                label="Plan"
                labelData={plan}
                tooltipText={planTooltipText}
              />
            </Grid>
          </Grid>
          {(!isCurrentlyPaying || isDialogOpen) && (
            <Grid item style={{ textAlign: 'center' }}>
              {/*
              <Button
                size="small"
                onClick={() => dispatch(actions.triggerPubSubRefreshUsers())}
              >
                <RefreshIcon fontSize="small" />
              </Button>
              */}
              <UpgradePlanButton
                toOpen={openUpgrade}
                setIsDialogOpen={setIsDialogOpen}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </CardProgress>
  );
};

const NonAdminAlert = ({ isAdmin }) =>
  isAdmin ? (
    ''
  ) : (
    <Grid item>
      <Card style={{ borderRadius: 10 }}>
        <Alert severity="info">
          <AlertTitle> Non Admin </AlertTitle>
          You can view but not change the settings. Please ask your Slack admins
          to change the settings for you. You can use AnonymityBot for that -
          for example:
          <i>
            /anon Can we please add #marketing to the whitelisted channels list
            for AnonymityBot?
          </i>
        </Alert>
      </Card>
    </Grid>
  );

const Dashboard = (props) => {
  const { isAdmin, teamId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { isSignedIn } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  React.useEffect(() => {
    if (isSignedIn) {
      dispatch(actions.listenToTeamData({ teamId }));
    }
  }, [isSignedIn, teamId]);

  if (props.inProgress) {
    return <LoadingPage />;
  }

  if (!isSignedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      spacing={isMobile ? 0 : 2}
      style={{
        flex: 1,
        maxWidth: '1024px',
        paddingLeft: 10,
        paddingRight: 10,
        margin: '0 auto',
        width: '100%',
        paddingBottom: 30,
      }}
    >
      <Grid item xs={12} sm={12}>
        <GodPanel />
      </Grid>

      <Grid item xs={12} sm={4} style={{ marginBottom: isMobile ? 16 : 0 }}>
        <Grid spacing={2} direction="column" container>
          <Grid item>
            <TeamInfo />
          </Grid>
          <Grid item>
            <MonthlyUsagePanel />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={8}>
        <Grid spacing={2} direction="column" container>
          <NonAdminAlert isAdmin={isAdmin} />
          <Grid item>
            <GeneralSettingsPanel />
          </Grid>
          <Grid item>
            <WhitelistPanel />
          </Grid>
          <Grid item>
            <PerspectivePanel />
          </Grid>
          {/*
           */}
          <Grid item>
            <WhisperPanel />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.error || state.database.error,
  inProgress:
    !state.auth.isInitialized ||
    state.auth.inProgress ||
    state.database.user.inProgress ||
    state.database.channels.inProgress ||
    state.database.team.inProgress ||
    !state.database.team.success,
  isSignedIn: state.auth.isSignedIn,
  userData: state.database.user.data,
  teamData: state.database.team.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
