// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import 'isomorphic-fetch';
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import firebase from '../../../backend/firebase';
import InputAdornment from '@mui/material/InputAdornment';

export const getTeams = async (startsWith) => {
  const teamsPath = `/slack_teams`;
  let query = firebase.firestore.collection(teamsPath);
  if (startsWith) {
    const upperBound = startsWith.replace(/.$/, (c) =>
      String.fromCharCode(c.charCodeAt(0) + 1)
    );
    query = query
      .where('team_domain', '>=', startsWith)
      .where('team_domain', '<', upperBound);
  }
  const snapshot = await query.orderBy('team_domain').limit(50).get();
  const options = snapshot.docs.map((doc) => doc.data());
  return options;
};

export default function Asynchronous(props) {
  const { disabled, setSelectedTeamDomain } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    const startsWith = value;
    let active = true;
    setLoading(true);
    setOptions([]);

    (async () => {
      const options = await getTeams(startsWith);
      if (active) {
        setLoading(false);
        setOptions(options);
      }
    })();
    return () => {
      active = false;
    };
  }, [value, open]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return (
    <Autocomplete
      style={{ width: 300 }}
      open={open}
      onOpen={async () => {
        setOpen(true);
        await getTeams('');
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.team_domain}
      options={options}
      loading={loading}
      onChange={(event, value) => {
        setSelectedTeamDomain(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Team domain"
          placeholder="Type a team domain name"
          fullWidth
          onChange={({ target: { value } }) => setValue(value)}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            disabled: disabled,
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
