import React, { useEffect } from 'react';
import './assets/App.css';
import SimpleAppBar from './Header/SimpleAppBar';
import Main from './Main/';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { listenToAuthStateChange } from '../actions';
import LoadingPage from './Common/LoadingPage';
import Notifier from './Common/Notifier';

const { REACT_APP_GA_ID } = process.env;

ReactGA.initialize(REACT_APP_GA_ID, {
  //gaAddress: 'https://wombat.anonymitybot.com/js/app.js',
});

ReactGA.pageview(window.location.pathname + window.location.search);

const App = (props) => {
  const { listenToAuthStateChange } = props;
  useEffect(() => {
    listenToAuthStateChange();
  }, [listenToAuthStateChange]);

  if (props.authInProgress) {
    return <LoadingPage />;
  }

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <Notifier />
      <SimpleAppBar />
      <Main />
    </div>
  );
};

const mapStateToProps = (state) => ({
  emailSent: state.auth.emailSent,
  error: state.auth.error,
  authInProgress: state.auth.inProgress,
});

const mapDispatchToProps = { listenToAuthStateChange };

export default connect(mapStateToProps, mapDispatchToProps)(App);
