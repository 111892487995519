import React from 'react';
import { Card, LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  card: {
    marginLeft: '10',
    marginRight: '10',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: 15,
  },
  cardDiv: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
});

const CardProgress = ({ inProgress, classes, children }) => (
  <Card className={classes.card} elevation={1}>
    {inProgress && (
      <LinearProgress style={{ width: '100%', marginBottom: '16px' }} />
    )}
    <div className={classes.cardDiv}>{children}</div>
  </Card>
);

export default withStyles(styles)(CardProgress);
