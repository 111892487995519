/* eslint-disable */
import React from 'react';
import {
  Typography,
  Divider,
  CardHeader,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  InputAdornment,
  Grid,
  Button,
} from '@mui/material';
import CardProgress from '../../Common/CardProgress';

import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from '../../../actions';
import triangularFlag from '../../assets/flag.svg';
import heartBroken from '../../assets/heart-broken-line.svg';
import InfoTooltip from './InfoTooltip';
import ContainedButton from '../../Common/ContainedButton';
import SentimentSlider from '../../Common/SentimentSlider';
import LanguageSelect from '../../Common/LanguageSelect';
import CelebrationIcon from '@mui/icons-material/Celebration';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: 16,
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: 10,
    textTransform: 'none',
  },
  checkboxLabel: {
    fontSize: '12px',
  },
}));

const getConfigFromTeamData = (teamData) => {
  const config = {};
  const defaultConfig = {
    //whitelist_enabled: false,
    channel_notifications_enabled: false,
    nr_required_flag_reports: 3,
    karma_threshold: 0,
    disable_negative_karma_on_polls: false,
    disable_negative_karma_on_msgs: false,
    disable_links_in_msgs: false,
    remove_show_created_with_anon: false,
    //disable_private_channels: false,
    //disable_anonymous_polls: false,
    min_message_sentiment: -100,
    max_message_sentiment: 100,
    min_shoutout_sentiment: 20,
    max_shoutout_sentiment: 100,
    allowed_languages: [],
    hide_perspective_image_when_within_limits: false,
    disable_hide_reply_pseudonym: false,
  };

  Object.keys(defaultConfig).forEach((key) => {
    if (key in teamData) {
      config[key] = teamData[key];
    }
  });

  return { ...defaultConfig, ...config };
};

const TooltipTitle = (props) => (
  <>
    <Grid container spacing={1} direction="column">
      {props.title && (
        <Grid item>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            {props.title}
          </Typography>
        </Grid>
      )}
      <Grid item>{props.content}</Grid>
      {props.link && (
        <Grid item>
          <Button
            target="_blank"
            size="small"
            variant="outlined"
            style={{ textTransform: 'none', fontWeight: 'bold' }}
            href={props.link}
          >
            Learn More
          </Button>
        </Grid>
      )}
    </Grid>
  </>
);

const GeneralSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const teamData = useSelector((state) => state.database.team.data);
  const inProgress = useSelector(
    (state) => state.database.features.settings.inProgress
  );
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const storedTeamConfig = getConfigFromTeamData(teamData);
  const [state, setState] = React.useState(storedTeamConfig);

  const isDisabled = !isAdmin || inProgress;

  const {
    channel_notifications_enabled,
    nr_required_flag_reports,
    karma_threshold,
    disable_negative_karma_on_msgs,
    disable_negative_karma_on_polls,
    remove_show_created_with_anon,
    disable_links_in_msgs,
    //disable_anonymous_polls,
    //disable_private_channels,
    min_message_sentiment,
    max_message_sentiment,
    min_shoutout_sentiment,
    max_shoutout_sentiment,
    allowed_languages,
    hide_perspective_image_when_within_limits,
    disable_hide_reply_pseudonym,
  } = state;

  const handleClick = () => {
    const settings = state;
    dispatch(updateSettings(settings));
  };
  const handleCheckboxChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const handleSelectChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleMessageSentimentSliderChange = (value1, value2) => {
    const newState = {
      ...state,
      min_message_sentiment: value1,
      max_message_sentiment: value2,
    };
    setState(newState);
  };

  const handleShoutoutSentimentSliderChange = (value1) => {
    const newState = {
      ...state,
      min_shoutout_sentiment: value1 > 20 ? value1 : 20,
    };
    setState(newState);
  };

  const handleAllowedLanguagesSelectChange = (values) => {
    console.log({ values });
    const newState = {
      ...state,
      allowed_languages: values,
    };
    console.log({ newState });
    setState(newState);
  };

  return (
    <CardProgress inProgress={inProgress}>
      <CardHeader
        style={{ padding: '16px 24px' }}
        title={
          <div>
            <Typography
              variant="h5"
              style={{
                display: 'inline-flex',
                justifyContent: 'start',
              }}
              gutterBottom
            >
              General Settings
            </Typography>
            {/*!isAdmin && <Chip variant="outlined" label="Not Admin" />*/}
          </div>
        }
        subheader="Customize AnonymityBot to fit your team needs"
        subheaderTypographyProps={{
          style: { fontSize: '14px', fontWeight: 400, color: '#546e7a' },
        }}
      />
      <Divider />
      <CardContent style={{ padding: '16px 24px' }}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Grid container spacing={0.5} direction="column">
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={channel_notifications_enabled}
                      onChange={handleCheckboxChange(
                        'channel_notifications_enabled'
                      )}
                      value="channel_notifications_enabled"
                      disabled={isDisabled}
                    />
                  }
                  label={
                    <>
                      <Typography style={{ fontSize: '14px' }}>
                        Allow usage of @here, @channel and @everybody in
                        anonymous messages.
                      </Typography>
                    </>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item>
              <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={disable_negative_karma_on_msgs}
                      onChange={handleCheckboxChange(
                        'disable_negative_karma_on_msgs'
                      )}
                      value="disable_negative_karma_on_msgs"
                      disabled={isDisabled}
                    />
                  }
                  label={
                    <>
                      <Typography
                        style={{ fontSize: '14px', display: 'inline' }}
                      >
                        Disable negative Karma on anonymous messages.
                      </Typography>
                      <InfoTooltip
                        title={
                          <TooltipTitle
                            content="You can foster better critical thinking by your team with non-negative Karma"
                            link="https://anonymitybot.com/blog/encouraging-contrarian-thoughts-in-slack/"
                          />
                        }
                      />
                    </>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={disable_negative_karma_on_polls}
                      onChange={handleCheckboxChange(
                        'disable_negative_karma_on_polls'
                      )}
                      value="disable_negative_karma_on_polls"
                      disabled={isDisabled}
                    />
                  }
                  label={
                    <>
                      <Typography
                        style={{ fontSize: '14px', display: 'inline' }}
                      >
                        Disable negative Karma on anonymous polls.
                      </Typography>
                      <InfoTooltip
                        title={
                          <TooltipTitle
                            content="You can foster better critical thinking by your team with non-negative Karma"
                            link="https://anonymitybot.com/blog/encouraging-contrarian-thoughts-in-slack/"
                          />
                        }
                      />
                    </>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={disable_links_in_msgs}
                      onChange={handleCheckboxChange('disable_links_in_msgs')}
                      value="disable_links_in_msgs"
                      disabled={isDisabled}
                    />
                  }
                  label={
                    <>
                      <Typography
                        style={{ fontSize: '14px', display: 'inline' }}
                      >
                        Disable links in messages.
                      </Typography>
                    </>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item>
              <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={remove_show_created_with_anon}
                      onChange={handleCheckboxChange(
                        'remove_show_created_with_anon'
                      )}
                      value="remove_show_created_with_anon"
                      disabled={isDisabled}
                    />
                  }
                  label={
                    <>
                      <Typography
                        style={{ fontSize: '14px', display: 'inline' }}
                      >
                        {`Remove the "Created with /anon" prompt on messages and polls.`}
                      </Typography>

                      <InfoTooltip title="If your team already knows how to use AnonymityBot, you can save some screen space by removing the prompt." />
                    </>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item>
              <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={hide_perspective_image_when_within_limits}
                      onChange={handleCheckboxChange(
                        'hide_perspective_image_when_within_limits'
                      )}
                      value="hide_perspective_image_when_within_limits"
                      disabled={isDisabled}
                    />
                  }
                  label={
                    <>
                      <Typography
                        style={{ fontSize: '14px', display: 'inline' }}
                      >
                        {`Hide perspective image when text score is within limits.`}
                      </Typography>

                      <InfoTooltip title="By default we always show a radar image of the perceived impact (see Perspective Limits below), even if the text score is within the workspace limits." />
                    </>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item>
              <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={disable_hide_reply_pseudonym}
                      onChange={handleCheckboxChange(
                        'disable_hide_reply_pseudonym'
                      )}
                      value="disable_hide_reply_pseudonym"
                      disabled={isDisabled}
                    />
                  }
                  label={
                    <>
                      <Typography
                        style={{ fontSize: '14px', display: 'inline' }}
                      >
                        {`Disable hidden pseudonyms in threads`}
                      </Typography>

                      <InfoTooltip title="Disables the ability to allow people to hide behind a RX pseudonym." />
                    </>
                  }
                />
              </FormGroup>
            </Grid>

            {/*}
          <FormGroup>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={
                <Checkbox
                  color="primary"
                  checked={disable_private_channels}
                  onChange={handleChange('disable_private_channels')}
                  value="disable_private_channels"
                  disabled={isDisabled}
                />
              }
              label={
                <Typography style={{ fontSize: '14px' }}>
                  Disable usage of AnonymityBot in <b>private</b> channels.{' '}
                </Typography>
              }
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={
                <Checkbox
                  color="primary"
                  checked={disable_anonymous_polls}
                  onChange={handleChange('disable_anonymous_polls')}
                  value="disable_anonymous_polls"
                  disabled={isDisabled}
                />
              }
              label={
                <Typography style={{ fontSize: '14px' }}>
                  Disable anonymous polls.
                </Typography>
              }
            />
          </FormGroup>
          */}
          </Grid>
        </FormControl>
        <Grid container spacing={4} direction="row" style={{ marginTop: 5 }}>
          <Grid item>
            <LanguageSelect
              isDisabled={isDisabled}
              stateValues={allowed_languages}
              handleAllowedLanguagesSelectChange={
                handleAllowedLanguagesSelectChange
              }
            />
          </Grid>

          <Grid item>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontWeight: 400, display: 'inline' }}
                >
                  Flag reports
                </Typography>
                <InfoTooltip title="Messages can be flagged by the team by reaching a number of flag reports." />
              </Grid>
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Flags</InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={nr_required_flag_reports}
                    onChange={handleSelectChange('nr_required_flag_reports')}
                    label="Flags"
                    style={{ width: 100 }}
                    disabled={isDisabled}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={triangularFlag} height="20" />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontWeight: 400, display: 'inline' }}
                >
                  Karma threshold
                </Typography>
                <InfoTooltip title="Messages with Karma below the threshold will be hidden." />
              </Grid>
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Karma</InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={karma_threshold}
                    onChange={handleSelectChange('karma_threshold')}
                    label="Karma"
                    style={{ width: 100 }}
                    disabled={isDisabled}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={heartBroken} height="20" />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={-1}>-1</MenuItem>
                    <MenuItem value={-2}>-2</MenuItem>
                    <MenuItem value={-3}>-3</MenuItem>
                    <MenuItem value={-4}>-4</MenuItem>
                    <MenuItem value={-5}>-5</MenuItem>
                    <MenuItem value={-6}>-6</MenuItem>
                    <MenuItem value={-7}>-7</MenuItem>
                    <MenuItem value={-8}>-8</MenuItem>
                    <MenuItem value={-9}>-9</MenuItem>
                    <MenuItem value={-10}>-10</MenuItem>
                    <MenuItem value={-999}>Limitless</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4} style={{ marginTop: 5 }}>
          <Grid item>
            <SentimentSlider
              value0={min_message_sentiment}
              value1={max_message_sentiment}
              isDisabled={isDisabled}
              handleSliderChange={handleMessageSentimentSliderChange}
              title="Message sentiment"
              tooltip={
                `All messages are scored with AI for their sentiment. ` +
                `You can automatically moderate content by choosing a sentiment range. ` +
                `For example, you can choose a range of -25 to 100 to approximately allow for neutral-positive messages.`
              }
            />
          </Grid>
          <Grid item>
            <SentimentSlider
              value0={min_shoutout_sentiment}
              value1={max_shoutout_sentiment}
              isDisabled={isDisabled}
              handleSliderChange={handleShoutoutSentimentSliderChange}
              title="Shoutout sentiment"
              tooltip={
                `Shoutouts are positive words and therefore ` +
                `the minimum sentiment is bounded to 20 (can be increased) ` +
                `and the maximum is fixed at 100.`
              }
              Icon={CelebrationIcon}
            />
          </Grid>
        </Grid>
      </CardContent>

      <Divider />
      <CardActions style={{ paddingLeft: 24, paddingRight: 24 }}>
        <ContainedButton
          onClick={handleClick}
          disabled={isDisabled}
          loading={inProgress}
        >
          Save Changes
        </ContainedButton>
      </CardActions>
    </CardProgress>
  );
};
export default GeneralSettings;
